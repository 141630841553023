import {
  Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

interface SelectionOption {
  id: string;
  label: string;
  name: string;
  description: string;
  icon: string;
  value: any;
  sticky?: boolean;
}

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  })
export class SelectDropdownComponent<T extends SelectionOption> implements OnInit, OnChanges {
  @Input() selected: string;

  @Output() selectedChanged = new EventEmitter<T>();

  @Input() options: T[];

  @Input() placeholder: string;

  @Input() clearSelectionLabel: string;

  @Input() disabled: boolean;

  @ViewChild('dropdown') dropdown: BsDropdownDirective;

  filter: string;

  filteredOptions: T[];

  value: T;

  ngOnInit(): void {
    this.initSelector();
  }

  ngOnChanges(sch: SimpleChanges) {
    if (sch.selected) {
      this.value = this.options?.find(o => o.id === sch.selected.currentValue);
    }

    if (sch.options) {
      this.initSelector();
    }
  }

  initSelector() {
    this.clearFilter();
  }

  applyFilter(filter: string) {
    this.filter = filter;
    this.filteredOptions = this.options.filter((op) => (
      op.sticky
      || op.label?.toLowerCase().includes(filter.toLowerCase())
    ));
  }

  selectOption(option: T) {
    if (!option) {
      this.clearSelection();
      return;
    }

    this.value = option;
    this.selectedChanged.emit(option);
    this.dropdown.hide();
  }

  clearSelection() {
    if (this.value) {
      this.value = undefined;
      this.selectedChanged.emit(undefined);
    }
    this.dropdown.hide();
  }

  clearFilter() {
    this.filter = '';
    this.filteredOptions = this.options;
  }
}
