import { NgModule } from '@angular/core';
import {
  CommonModule, DatePipe, DecimalPipe
} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FiltersToolbarComponent } from './components/filters-toolbar/filters-toolbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiltersToggleComponent } from './components/filters-toggle/filters-toggle.component';
import { FiltersMenuComponent } from './components/filters-menu/filters-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FiltersService } from './services/filters.service';
import { MatMenuModule } from '@angular/material/menu';
import { FiltersToolbarAnchorComponent } from './components/filters-toolbar-anchor/filters-toolbar-anchor.component';
import { onlyNumbersDirective } from './directives/onlynumbers.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DateRangeHeaderComponent } from './components/date-range-header/date-range-header.component';

@NgModule({
  declarations: [
  FiltersToolbarComponent,
  FiltersToggleComponent,
  FiltersMenuComponent,
  FiltersToolbarAnchorComponent,
  onlyNumbersDirective,
  DateRangeHeaderComponent,
  ],
  imports: [
  CommonModule,
  FormsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatTooltipModule,
  MatButtonModule,
  MatExpansionModule,
  MatRadioModule,
  MatInputModule,
  MatCheckboxModule,
  MatMenuModule,
  ScrollingModule,
  ],
  exports: [
  FiltersToggleComponent,
  FiltersToolbarAnchorComponent,
  ],
  providers: [
  DatePipe,
  DecimalPipe,
  MatDatepickerModule,
  FiltersService,
  ]
  })
export class FiltersModule { }
