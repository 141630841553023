import { SubSink } from 'subsink';
import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { RowDetailsChangedService } from 'src/app/_core/services/common/row-details-changed/row-details-changed.service';
import { formatGrid, userTooltips } from '../../helpers/listRole';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

interface RowData {
  column: string;
  value: any;
  name?: string;
  format?: string;
}
@Component({
  selector: 'app-row-detail',
  templateUrl: './row-detail.component.html',
  styleUrls: ['./row-detail.component.scss']
})
export class RowDetailComponent implements OnInit, OnDestroy {
  // Variables in order to download PDF
  @Input() downloadLabel = 'Descargar';

  @Input() complaintLabel = 'Reclamar';

  @Input() showSpinner = false;

  @Input() showComplaintSpinner = false;

  @Output() downloadPDF = new EventEmitter<string>();

  @Output() complaint = new EventEmitter<RowData[]>();

  rowData: Array<RowData>;

  rawData: any;

  hasToShowData: boolean;

  showDownloadButton: boolean;

  showComplaintButton: boolean;

  complaintButtonDisabled: boolean;

  hasToShowCopyOption: boolean;

  snackbarConfig: MatSnackBarConfig<any> = {
    duration: 10000,
    verticalPosition: 'top'
  };

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform = formatGrid;

  private subs = new SubSink();

  constructor(
    // used in order to emit row data.
    private rowDetailsChangedService: RowDetailsChangedService,
    // Service that allows to suscribe to cuit changes
    private cuitChangedService: CuitChangedService,
    // used for toast message
    private snackBar: MatSnackBar
  ) {
    this.hasToShowData = false;
    this.showDownloadButton = false;
    this.hasToShowCopyOption = false;
    this.showComplaintButton = false;
    this.complaintButtonDisabled = false;
  }

  ngOnInit() {
    this.subs.sink = this.rowDetailsChangedService.showRowDetails
      .subscribe((data) => this.showRowData(data));
    this.subs.sink = this.cuitChangedService.activeCuitChanged
      .subscribe(() => this.close());
    this.subs.sink = this.rowDetailsChangedService.closeRowDetails.subscribe(this.close.bind(this));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Shows selected row data.
   * @param data row data
   */
  showRowData(data: any) {
    this.rawData = data.rawData;
    this.rowData = data.rowData;
    this.hasToShowData = true;
    this.hasToShowCopyOption = !!data.showCopyOption;
    this.showDownloadButton = !!data.showDownloadButton;
    this.showComplaintButton = !!data.showComplaintButton;
    this.complaintButtonDisabled = !!data.complaintButtonDisabled;
  }

  /**
   * If necessary we show copy to clipboard option
   * @param column column
   */
  showCopyOption(column: string) {
    return !!(this.hasToShowCopyOption
      && (column === 'nroCertificado' || column === 'codSeguridad')
    );
  }

  /**
   * Checks if has to show Copy Option and sets user tooltips.
   */
  checkCopyOption() {
    if (this.hasToShowCopyOption) {
      userTooltips();
    }
  }

  /**
   * Calls function in order to download PDF.
   */
  download() {
    this.downloadPDF.next('download');
  }

  /**
   * Calls function in order to open a complaint.
   */
  makeAComplaint() {
    if (this.complaintButtonDisabled) return;
    this.complaint.next(this.rawData);
  }

  /**
   * Copy value to clipboard.
   * @param value value
   * @param columnName column name
   */
  copyValue(value: string, columnName: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open(`Se ha copiado el ${columnName} al portapapeles!`, 'Cerrar', this.snackbarConfig);
  }

  /**
   * Close row data details.
   */
  close() {
    this.hasToShowData = false;
    this.showDownloadButton = false;
  }
}
