import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { formatDateParam } from '../../../_shared/helpers/dates';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  constructor(
    private http: ApiService,
    public userDataService: UserDataService,
    private gridService: GridQueriesService,
  ) { }

  /**
   * Service for Applications List
   * @param page page
   * @param count count
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listApplications(page: number, count: number, from: string, to: string,
    orderBy: string, order: string, filters: any) {
    return this.gridService.getGridData('contratos/aplicaciones', {
      page,
      count,
      from,
      to,
      orderBy,
      order,
      filters
    });
  }

  /**
   * Service for Applications Totals
   * @param from from
   * @param to to
   * @param filters filters
   */
  listApplicationsTotals(from: string, to: string, filters: any) {
    return this.gridService.getTotals('contratos/aplicaciones-totales', {
      from, to, filters
    });
  }

  /**
   * Download a XLS associated with this service.
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   * @param columns columns
   */
  downloadXls(from: string, to: string, orderBy: string,
    order: string, filters: any, columns: Array<string>) {
    return this.gridService.downloadXls('contratos/aplicaciones', {
      from, to, orderBy, order, filters, columns
    });
  }

  /**
   * Returns data for kpi.
   * @param from from
   * @param to to
   * @param filters filters
   */
  getKpiData(from: Date, to: Date, filters: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('desde', from ? formatDateParam(from) : '')
      .set('hasta', to ? formatDateParam(to) : '');

    params = this.http.addFilterParams(filters, params);

    return this.http.get('contratos/aplicaciones/estadisticas-mensuales', params);
  }

  /**
   * Gets column list for unloads grid based on the active user's cuit
   */
  listAplicationsColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/aplicaciones', params);
  }
}
