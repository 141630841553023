import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-etica-message-modal',
  templateUrl: './etica-message-modal.component.html',
  styleUrls: ['./etica-message-modal.component.scss']
})
export class EticaMessageModalComponent {
  constructor(
    public dialogRef: MatDialogRef<EticaMessageModalComponent>
  ) {}

  /**
   * Close dialog when user click go back button
   */
  public closeDialog() {
    this.dialogRef.close();
  }
}
