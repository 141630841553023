import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { HarvestsService } from 'src/app/_core/services/harvests/harvests.service';
import { ProductsService } from 'src/app/_core/services/products/products.service';
import { formatGrid } from 'src/app/_shared/helpers/listRole';
import { FiltersConfig, SelectableFilterOption } from 'src/app/_shared/modules/agd-components/filters/models/filters.types';
import { SubSink } from 'subsink';

interface NavigationState {
  preloadFilters?: Record<string, any>;
}

class TableState {
  filters: Record<string, any> = {};

  order = '';

  orderBy = '';

  search = '';

  count = 10;

  page = 1;

  status = '';
}

/**
 * Base class for data table displays
 */
@Component({
  selector: 'agd-data-list-base',
  template: ''
  })
export abstract class DataListBaseClass implements OnInit {
  filters: FiltersConfig;

  filtersOptions: Record<string, Promise<SelectableFilterOption[]>> = {};

  tableattr = new TableState();

  protected subs = new SubSink();

  /**
   * Used in order to cancel request when component is destroyed.
   */
  protected ngUnsubscribe = new Subject();

  protected router = inject(Router);

  private _navigationState: NavigationState;

  constructor(
    private productsService?: ProductsService,
    private harvestService?: HarvestsService,
  ) {
    this.getNavigationState();
  }

  ngOnInit(): void {
    if (this.productsService && this.harvestService) {
      this.getFilters();
    }

    this.getFiltersFromState();
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format, cell) {
    return formatGrid(format, cell);
  }

  /**
   * set the 'filters' property with the configuration object
   */
  protected loadFiltersConfig(): void {
    this.filters = this.getFiltersConfig();
  }

  /**
   * Get list of Filters to use in DropDown selects
   */
  protected getFilters() {
    this.filtersOptions.producto = this.productsService.getProducts()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((res: any) => (
          res.productos.map(product => ({ value: product, label: product }))
        ))
      ).toPromise();

    this.filtersOptions.cosecha = this.harvestService.getHarvests()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((res: any) => (
          res.cosechas.map(crop => ({ value: crop, label: crop }))
        ))
      ).toPromise();
  }

  protected navigateWithFilter(url: string[], name: string, value: any) {
    this.router.navigate(url, {
      state: {
        preloadFilters: { [name]: value }
      }
    });
  }

  private getFiltersFromState() {
    const filterConfig = this.getFiltersConfig();

    const { preloadFilters }: NavigationState = this._navigationState || {};

    if (preloadFilters && Object.keys(preloadFilters).length) {
      // If exists any pre-loadaded filters we add them to the configuration and filters values
      Object.keys(preloadFilters).forEach(key => {
        if (filterConfig[key]) {
          let value = preloadFilters[key];
          // Parse number value
          // mejora, usar formato configurado por filtro o int por defecto
          if ((filterConfig[key].type === 'number'
            || filterConfig[key].type === 'number-range')
            && !Number.isNaN(Number(value))) {
            value = Number(value);
          }

          filterConfig[key].initValue = value;
          this.tableattr.filters[key] = value;
        }
      });
    }

    this.filters = filterConfig;
  }

  private getNavigationState() {
    const navigation = this.router.getCurrentNavigation();

    if (!navigation) return;

    const { state } = navigation.extras;

    this._navigationState = state;
  }

  /**
   * @abstract gets the object to be used as filters configuration
   */
  protected abstract getFiltersConfig(): FiltersConfig;
}
