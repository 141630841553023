import {
  Component, Input, Output, EventEmitter
} from '@angular/core';
import { formatGrid } from '../../helpers/listRole';
import pick from 'lodash/pick';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent {
  @Input() filters;

  @Input() modalFilters;

  @Output() getData = new EventEmitter<string>();

  @Output() completeSubscription = new EventEmitter<string>();

  @Output() removeDateFilter = new EventEmitter<string>();

  @Output() removeIdFilter = new EventEmitter<{ idFilterName: string }>();

  @Output() removeFromToFilter = new EventEmitter<{ filterFrom: string; filterTo: string }>();

  /**
   * Remove filters applied and reload table
   */
  public removeFilter(filter) {
    if (this.filters[filter]) {
      delete this.filters[filter];
      if (filter === 'fecha' || filter === 'fechaComprobante' || filter === 'fechaContrato') {
        this.removeDateFilter.next('');
      } else if (this.modalFilters[filter].key === 'kg' || this.modalFilters[filter].key === 'numberRange'
        || this.modalFilters[filter].key === 'fechaVencimiento'
        || this.modalFilters[filter].key === 'fechaFinalizacion') {
        this.removeFromToFilter.emit({
          filterFrom: this.modalFilters[filter].filterFrom,
          filterTo: this.modalFilters[filter].filterTo
        });
      } else if (this.modalFilters[filter].type === 'id') {
        this.removeIdFilter.emit({
          idFilterName: this.modalFilters[filter].queryParamName
        });
      }
    }
    this.completeSubscription.next('');
    this.getData.next('');
  }

  /**
   * Return object keys that are in modalFilters object.
   * @param object object
   */
  public objectKeys(object) {
    return Object.keys(pick(object, Object.keys(this.modalFilters)));
  }

  /**
   * If filter is required returns true, else returns false.
   * @param filter filter name
   */
  public isRequired(filter): boolean {
    for (const key in this.modalFilters) {
      if (this.modalFilters[key].filterName === filter) {
        return this.modalFilters[key].required;
      }
    }
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format, cell) {
    return formatGrid(format, cell);
  }
}
