<div class="alert-wrap">
  <div class="alert alert-agd-agro alert-{{ type }} m-0" role="alert">
    <div class="alert-icon-container" [ngSwitch]="type">
      <span class="alert-icon material-icons-outlined" *ngSwitchCase="'warning'">
        warning
      </span>
      <span class="alert-icon material-icons-round" *ngSwitchCase="'danger'">
        feedback
      </span>
      <span class="alert-icon material-icons-outlined" *ngSwitchDefault>
        info_outline
      </span>
    </div>
    <span class="alert-content">
      <ng-content></ng-content>
    </span>
  </div>
</div>
