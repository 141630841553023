import FileSaver from 'file-saver';
import moment from 'moment';
import Pako from 'pako';
import { FileTypeEnum } from './applicationConstants';

export async function exportXLS(data: Blob, fileName: string) {
  const buffer = await (data as any).arrayBuffer();
  const dataArray = new Uint8Array(buffer);
  const bytes: string = atob(Pako.ungzip(dataArray, { to: 'string' }));

  const byteNumbers = new Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob: Blob = new Blob([byteArray], { type: 'application/vnd.ms-excel' });
  // File Name:
  const today = moment().format('DD-MM-YYYY');
  fileName = `${today} - AGD Agro - ${fileName}.xlsx`;
  FileSaver.saveAs(blob, fileName);
}

export function exportPDF(fileContent: string, fileData: any): void {
  const bytes: string = atob(fileContent);
  const byteNumbers = new Array(bytes.length);
  let fileName;
  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob: Blob = new Blob([byteArray], { type: 'application/pdf' });
  // File Name:
  if (fileData.documentType === FileTypeEnum.ORDEN_DE_PAGO) {
    // Set an special format name in case of 'orden de pago' file.
    fileName = `${fileData.ordenDePago} - AGD Agro.pdf`;
  } else {
    const today = moment().format('DD-MM-YYYY');
    fileName = `${today} - AGD Agro - ${fileData.name}.pdf`;
  }
  FileSaver.saveAs(blob, fileName);
}

export function exportKML(fileContent: string, fileData: any): void {
  const bytes: string = atob(fileContent);
  const byteNumbers = new Array(bytes.length);
  let fileName;
  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob: Blob = new Blob([byteArray], { type: 'application/vnd.google-earth.kml+xml' });
  // File Name:
  const today = moment().format('DD-MM-YYYY');
  fileName = `${today} - AGD Agro - ${fileData.name}.kml`;
  FileSaver.saveAs(blob, fileName);
}

export function capitalize(s: string): string {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
