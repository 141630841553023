import {
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnChanges {
  @Input() label: string;

  @Input() value = 0;

  @Input() maxValue: number;

  @Input() loading = false;

  @Input() color: string;

  percentage: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.maxValue) {
      this.percentage = (this.maxValue && Math.round((this.value / this.maxValue) * 100)) || 0;
    }
  }
}
