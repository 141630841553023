<!-- Filters Options -->
<div class="filters-wrap">

  <!-- Icons Filters -->
  <div class="filter-header">
      <agd-filters-toggle
        [filters]="filters"
        (filterValueChange)="applyFilters($event)">
      </agd-filters-toggle>
      <div class="filters-w-icons">
        <i class="icofont-cloud-download" [hidden]="showSpinner" (click)="downloadXls()" title="Descargar Excel"
          data-toggle="tooltip" data-placement="right"></i>
        <i [hidden]="!showSpinner">
          <mat-progress-spinner mode="indeterminate" diameter="19">
          </mat-progress-spinner>
        </i>
      </div>
    </div>
    <agd-filters-toolbar-anchor></agd-filters-toolbar-anchor>

</div>

<!-- Contenedor Tabla -->
<div class="main-table">
  <div class="mat-table-container">

    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource!==undefined">

      <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
          <div *ngIf="headerColumns[i].hasDropDown" class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle boton"
                    aria-controls="dropdown-basic">{{headerColumns[i].name}}
            </button>
            <div *ngIf="headerColumns[i].key !== 'kg'">
              <ul *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="orderTable(headerColumns[i].orderBy,'asc')"><i class="icofont icofont-arrow-up"></i>Orden ascendente</a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="orderTable(headerColumns[i].orderBy,'desc')"><i class="icofont icofont-arrow-down"></i>Orden descendente</a></li>
              </ul>
            </div>
            <!-- Default Kg Filters -->
            <div *ngIf="headerColumns[i].key === 'kg'">
              <ul *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="orderTable(headerColumns[i].orderBy,'asc')"><i class="icofont icofont-arrow-up"></i>Orden ascendente</a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="orderTable(headerColumns[i].orderBy,'desc')"><i class="icofont icofont-arrow-down"></i>Orden descendente</a></li>
              </ul>
            </div>
            <!-- /Default Kg Filters -->
          </div>
          <span *ngIf="!headerColumns[i].hasDropDown" class="mat-label" mat-sort-header matSort (matSortChange)="sortData($event)">{{headerColumns[i].name}}</span>
        </mat-header-cell>
        <mat-cell (click)="viewDetails(element)" *matCellDef="let element">{{transform(headerColumns[i].format,element[column])}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns; sticky: true" ></mat-header-row>
      <mat-row class="hovereable-row" tabindex="1" *matRowDef="let row; columns: dataColumns;" [hidden]="!(tableStatus === 'done')"></mat-row>
      <mat-footer-row [class.table-footer-hidden]="tableStatus !== 'done'" *matFooterRowDef="dataColumns"></mat-footer-row>
    </mat-table>
  </div>

  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumns"></app-table-status>
  </div>

  <div [hidden]="!(tableStatus === 'done')">
    <mat-paginator
      [length]="dataSourceTotal"
      [pageSize]="tableattr.count"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="handlePage($event)">
    </mat-paginator>
  </div>

</div>

<div class="grid-footer-label-container" [ngClass]="{ invisible: !(tableStatus === 'done') }">
  <div class="alert" role="alert">
    <span>Última actualización: {{ fechaUltimaActualizacionDatos | date: 'dd/MM/yy HH:mm' }}</span>
  </div>
</div>
