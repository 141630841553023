import {
  Component, Output, EventEmitter, Input
} from '@angular/core';

@Component({
  selector: 'app-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss']
})
export class EmojiSelectorComponent {
  @Input() keepOpen = true;

  @Output() emojiSelected = new EventEmitter();

  addEmoji(event) {
    this.emojiSelected.emit(event.emoji.native);
  }
}
