<div class="agd-filters-header">
  <div>{{filterLabel}}:</div>
  <button mat-button color="primary" *ngIf="clearable" (click)="clearFilters()" @fadeIn @fadeOut>
    Limpiar filtros
  </button>
</div>
<div class="agd-filter-item" *ngFor="let filter of config | keyvalue: objOrder">
  <ng-container [ngSwitch]="filter.value.type">
    <!-- Switch filter -->
    <div class="inline-filter" *ngSwitchCase="'switch'">
      <label>{{ filter.value.label }}</label>
      <mat-slide-toggle color="primary" [checked]="_currentValues?.[filter.key]?.value"
        (change)="applyFilter(filter.key, $event.checked)">
      </mat-slide-toggle>
    </div>
    <mat-expansion-panel *ngSwitchDefault>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="filter-title">
            <div>{{ filter.value.label }}</div>
            <div class="filter-value" *ngIf="values?.[filter.key]?.displayValue" @fadeIn @fadeOut>
              {{ values[filter.key].displayValue }}
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="input-item" [ngSwitch]="filter.value.type">
        <!-- Text filter -->
        <div class="input-container" *ngSwitchCase="'text'">
          <mat-form-field>
            <input matInput type="text" maxlength="100" autocomplete="off"
              [ngModel]="_currentValues?.[filter.key]?.value" (ngModelChange)="updateModelValue(filter.key, $event)"
              (keydown.enter)="applyFilter(filter.key)" placeholder="Escribir para buscar">
          </mat-form-field>
        </div>
        <!-- Number filter -->
        <div class="input-container" *ngSwitchCase="'number'">
          <mat-form-field>
            <input matInput onlyNumbers #numberValue onlyNumbers type="number" inputmode="numeric"
              [ngModel]="_currentValues?.[filter.key]?.value" (ngModelChange)="updateModelValue(filter.key, numberValue.value)"
              (keydown.enter)="applyFilter(filter.key)" placeholder="Escribir para buscar">
          </mat-form-field>
        </div>
        <!-- Number Range filter -->
        <div class="input-container" *ngSwitchCase="'number-range'">
          <mat-form-field>
            <input matInput onlyNumbers type="number" inputmode="numeric"
              [ngModel]="getNumberRangeValue(filter.key, 'from')"
              (ngModelChange)="onNumberRangeChange(filter.key, $event, 'from')" placeholder="Desde">
          </mat-form-field>
          <mat-form-field>
            <input matInput onlyNumbers type="number" inputmode="numeric"
              [ngModel]="getNumberRangeValue(filter.key, 'to')"
              (ngModelChange)="onNumberRangeChange(filter.key, $event, 'to')" placeholder="Hasta">
          </mat-form-field>
          <small class="text-danger" @fadeIn
            *ngIf="_currentValues?.[filter.key]?.value && _currentValues?.[filter.key]?.applyDisabled">
            El rango ingresado es inválido
          </small>
        </div>
        <!-- Radio filter -->
        <div class="input-container" *ngSwitchCase="'radio'">
          <ng-container *ngIf="filter.value.type === 'radio'">
            <mat-radio-group labelPosition="before" color="primary" [value]="_currentValues?.[filter.key]?.value"
              (change)="applyFilter(filter.key, $event.value)">
              <mat-radio-button *ngFor="let option of filter.value.options | async" [value]="option.value">
                {{ option.label }}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
        </div>
        <!-- Select multiple filter -->
        <div class="input-container" *ngSwitchCase="'select'">
          <ng-container *ngIf="filter.value.type === 'select'">
            <mat-checkbox *ngFor="let option of filter.value.options | async" color="primary" labelPosition="before"
              [checked]="isCheckedValue(filter.key, option.value)"
              (change)="applyCheckboxValue(filter.key, $event.checked, option.value)">
              {{ option.label }}
            </mat-checkbox>
          </ng-container>
        </div>
        <!-- Date filter -->
        <div class="input-container" *ngSwitchCase="'date'">
          <ng-container *ngIf="filter.value.type === 'date'">
            <mat-calendar [selected]="_currentValues?.[filter.key]?.value" [minDate]="filter.value.min"
              [maxDate]="filter.value.max" (selectedChange)="updateModelValue(filter.key, $event)">
            </mat-calendar>
          </ng-container>
        </div>
        <!-- Date range filter -->
        <div class="input-container" *ngSwitchCase="'date-range'">
          <ng-container *ngIf="filter.value.type === 'date-range'">
            <mat-calendar [headerComponent]="_dateRangeHeader" [selected]="_currentValues?.[filter.key]?.value"
              [minDate]="filter.value.min" [maxDate]="filter.value.max"
              (selectedChange)="onDateRangeChange(filter.key, $event)">
            </mat-calendar>
          </ng-container>
        </div>
        <div class="filter-actions">
          <button mat-button color="dark" *ngIf="values?.[filter.key]?.value && !filter.value?.required" @fadeIn
            @fadeOut (click)="clearFilters(filter.key)">
            Limpiar filtro
          </button>
          <button mat-button color="primary" class="ml-auto" *ngIf="withApplyAction(filter.value.type)"
            (click)="applyFilter(filter.key)" [disabled]="_currentValues?.[filter.key]?.applyDisabled" @fadeIn @fadeOut>
            Aplicar
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </ng-container>
</div>