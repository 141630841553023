import {
  Component, Inject, ElementRef, ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-oficial-de-cuentas-modal',
  templateUrl: './add-oficial-de-cuentas-modal.component.html',
  styleUrls: ['./add-oficial-de-cuentas-modal.component.scss']
  })
export class AddOficialDeCuentasModalComponent {
  @ViewChild('oficialDeCuentaInput') OficialDeCuentaInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * Variables used for mat-chip-list control.
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];

  oficialDeCuentaCtrl = new UntypedFormControl();

  filteredOficialesDeCuenta: Observable<string[]>;

  /**
   * oficiales de cuenta selected.
   */
  oficialesDeCuenta: string[] = [];

  /**
   * Available oficiales de cuenta.
   */
  allOficialesDeCuenta: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddOficialDeCuentasModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.allOficialesDeCuenta = data.oficialesDeCuentaDisponibles;
    this.oficialesDeCuenta = data.oficialesDeCuentaAsociados;
    this.filteredOficialesDeCuenta = this.oficialDeCuentaCtrl.valueChanges.pipe(
      startWith(''),
      map(oficialDeCuenta => ((oficialDeCuenta && oficialDeCuenta.length >= 1) ? this._filter(oficialDeCuenta) : []))
    );
  }

  /**
   * Add method for mat-chip-list
   * @param event event
   */
  public add(event: MatChipInputEvent): void {
    const { input } = event;
    const value = event.value.trim();

    // Add our oficialDeCuenta
    if ((value || '')) {
      if (!this.oficialesDeCuenta.includes(value)) {
        this.oficialesDeCuenta.push(value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.oficialDeCuentaCtrl.setValue(null);
  }

  /**
   * Remove method for mat-chip-list
   * @param oficialDeCuenta oficial to remove
   */
  public remove(oficialDeCuenta: string): void {
    const index = this.oficialesDeCuenta.indexOf(oficialDeCuenta);

    if (index >= 0) {
      this.oficialesDeCuenta.splice(index, 1);
    }
  }

  /**
   * Selected method for mat-chip-list
   * @param event event
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue.trim();
    if (!this.oficialesDeCuenta.includes(value)) {
      this.oficialesDeCuenta.push(value);
    }
    this.OficialDeCuentaInput.nativeElement.value = '';
    this.oficialDeCuentaCtrl.setValue(null);
  }

  /**
   * Apply changes.
   */
  public apply() {
    this.dialogRef.close(
      {
        oficialesDeCuenta: this.oficialesDeCuenta
      }
    );
  }

  /**
   * Filter method for mat-chip-list
   * @param value
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allOficialesDeCuenta.filter(oficialDeCuenta => oficialDeCuenta.toLowerCase().indexOf(filterValue) === 0);
  }
}
