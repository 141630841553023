<div class="modal-header">
  <span class="modal-title center truncate">{{ data.title }}</span>
</div>

<div id="confirm-delete-modal" class="modal-wrapper">

  <!-- Content -->
  <section class="content-page container-fluid">

    <!-- Contenedor Form -->
    <div class="row">

      <div class="col-12">

        <!-- Question Field -->
        <div class="form-group">
          <p class="message">{{ data.message }} <b>{{ data.toDeleteLabel }}</b>?</p>
        </div>
        <!-- /Question Field -->

        <hr>

        <!-- Buttons -->
        <div class="text-center">
          <button type="button" class="btn btn-primary" (click)="confirmDelete()">
            <span *ngIf="!showSpinner">
              Si
            </span>
            <span *ngIf="showSpinner">
              <mat-progress-spinner mode="indeterminate" diameter="19">
              </mat-progress-spinner>
            </span>
          </button>
          <button type="button" class="btn btn-link" (click)="closeDialog()">No</button>
        </div>
        <!-- /Buttons -->
      </div>

    </div>

  </section>

</div>
