import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableColumnHeaderComponent } from './components/table-column-header/table-column-header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';



@NgModule({
  declarations: [
  TableColumnHeaderComponent,
  ],
  imports: [
  CommonModule,
  BsDropdownModule.forRoot(),
  ],
  exports: [
  TableColumnHeaderComponent,
  ]
  })
export class TableColumnModule { }
