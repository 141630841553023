<!-- Badges : These are shown when a filter is applied -->
<ng-container *ngFor="let key of objectKeys(filters)">
  <div class="grilla-filter-badge" *ngIf="!modalFilters[key]?.hideBadge">
    <i class="icon-close icofont-close" *ngIf="!isRequired(key)" (click)="removeFilter(key)" title="Eliminar"></i>
    <i class="icon-close icofont-close" *ngIf="isRequired(key)" title="Obligatorio"></i>
    <span>{{modalFilters[key].placeHolder}}: {{
      modalFilters[key].skipFormattingForBadge ? filters[key] : transform(modalFilters[key].format,filters[key])
    }}</span>
  </div>
</ng-container>
