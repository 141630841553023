<div class="modal-header">
  <span class="modal-title center truncate">Detalle de Orden de Pago: {{transform('wo-decimals-or-separators',
    payOrderNumber)}}</span>
</div>

<div id="list-filters-modal" class="modal-wrapper">
  <!-- Content -->
  <section class="content-page container-fluid">
    <!-- Contenedor Form -->
    <div class="row">
      <div class="col-12">
        <mat-tab-group (selectedTabChange)="getPayOrderData($event)">
          <!-- General Info Tab -->
          <mat-tab label="Info. general">

            <div id="details-form" class="box">
              <form>
                <div class="table-container">
                  <app-table-status *ngIf="tableStatus !== 'done'" [tableStatus]="tableStatus"></app-table-status>
                  <table class="table" *ngIf="tableStatus === 'done'">
                    <thead>
                      <tr>
                        <th scope="col">TÍTULO</th>
                        <th scope="col" class="text-right">DESCRIPCIÓN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Orden de Pago</td>
                        <td class="text-right">{{transform('wo-decimals-or-separators',
                          payOrderGeneralInfo.ordenDePago)}}</td>
                      </tr>
                      <tr>
                        <td>Centro Emisor</td>
                        <td class="text-right">{{ payOrderGeneralInfo.centroEmisor }}</td>
                      </tr>
                      <tr>
                        <td>Fecha</td>
                        <td class="text-right">{{payOrderGeneralInfo.fecha | date: 'dd/MM/yyyy'}}</td>
                      </tr>
                      <tr>
                        <td>Fecha Acreditación</td>
                        <td class="text-right">{{payOrderGeneralInfo.fechaAcreditacion | date: 'dd/MM/yyyy'}}</td>
                      </tr>
                      <tr>
                        <td width="30%">{{labelVendedor}}</td>
                        <td width="70%" class="text-right">{{vendedores}}</td>
                      </tr>
                      <tr>
                        <td>Corredor</td>
                        <td class="text-right">{{payOrderGeneralInfo.corredor}}</td>
                      </tr>
                      <tr>
                        <td>Importe</td>
                        <td class="text-right">{{transform('w-decimals', payOrderGeneralInfo.importe)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />

                <!-- Go Back Button -->
                <div class="text-center">
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="closeDialog()">Volver</button>
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="downloadOrdenDePagoPDF()">
                    <span *ngIf="showSpinnerOP">
                      <mat-progress-spinner mode="indeterminate" class="op-spinner" diameter="20" color="primary">
                      </mat-progress-spinner>
                    </span>
                    <span *ngIf="!showSpinnerOP">PDF Orden de Pago</span>
                  </button>
                </div>
                <!-- /Edit Button -->

                <br>

              </form>
            </div>
          </mat-tab>
          <!-- /General Info Tab -->
          <!-- Comprobantes Tab -->
          <mat-tab label="Comprobantes">
            <div id="details-form" class="box">
              <form>
                <div class="table-container">
                  <mat-table #table *ngIf="dataSource!==undefined && (dataColumnsProofs.length !== 0)"
                    [dataSource]="dataSource">
                    <!-- Columna: 1 Tipo Comprobante -->
                    <ng-container matColumnDef="tipoComprobante">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">TIPO COMPROBANTE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.tipoComprobante}}</mat-cell>
                    </ng-container>
                    <!-- Columna: 2 Nro. Comprobante -->
                    <ng-container matColumnDef="nroComprobante">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">NRO. COMPROBANTE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{transform('wo-decimals-or-separators',
                        element.nroComprobante)}}</mat-cell>
                    </ng-container>
                    <!-- Columna: 3 Nombre -->
                    <ng-container matColumnDef="nombre">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">NOMBRE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.nombre}}</mat-cell>
                    </ng-container>
                    <!-- Columna: 4 Fecha -->
                    <ng-container matColumnDef="fecha">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">FECHA</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{ element.fecha | date:'dd/MM/yyyy'}}</mat-cell>
                    </ng-container>
                    <!-- Columna: 5 Contrato -->
                    <ng-container matColumnDef="contrato">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">CONTRATO</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{transform('wo-decimals-or-separators', element.contrato)}}
                      </mat-cell>
                    </ng-container>
                    <!-- Columna: 6 Importe -->
                    <ng-container matColumnDef="importe">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">IMPORTE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{transform('w-decimals', element.importe)}}
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="dataColumnsProofs; sticky: true"></mat-header-row>
                    <mat-row class="clickeable-row" [hidden]="!(tableStatus === 'done')"
                      *matRowDef="let row; columns: dataColumnsProofs;">
                    </mat-row>
                  </mat-table>
                  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
                    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumnsProofs"></app-table-status>
                  </div>
                  <div [hidden]="!(tableStatus === 'done')">
                    <mat-paginator [length]="dataSourceTotal" [pageSize]="tableattr.count"
                      [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePage($event)">
                    </mat-paginator>
                  </div>
                </div>
                <hr />
                <!-- Buttons -->
                <div class="text-center">
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="closeDialog()">Volver</button>
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="downloadOrdenDePagoPDF()">
                    <span *ngIf="showSpinnerOP">
                      <mat-progress-spinner mode="indeterminate" class="op-spinner" diameter="20" color="primary">
                      </mat-progress-spinner>
                    </span>
                    <span *ngIf="!showSpinnerOP">PDF Orden de Pago</span>
                  </button>
                </div>
                <!-- /Buttons -->
                <br>
              </form>
            </div>
          </mat-tab>
          <!-- /Comprobantes Tab -->
          <!-- Instrumentos Tab -->
          <mat-tab label="Instrumentos">
            <div id="details-form" class="box">
              <form>
                <div class="table-container">
                  <mat-table #table *ngIf="dataSource!==undefined && (dataColumnsInstruments.length !== 0)"
                    [dataSource]="dataSource">
                    <!-- Instrumento -->
                    <ng-container matColumnDef="instrumento">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">INSTRUMENTO</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.instrumento}}</mat-cell>
                    </ng-container>
                    <!-- Nro.Cheque -->
                    <ng-container matColumnDef="nroCheque">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">NRO. CHEQUE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{transform('wo-decimals-or-separators', element.nroCheque)}}
                      </mat-cell>
                    </ng-container>
                    <!-- Cuenta -->
                    <ng-container matColumnDef="cuenta">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">CUENTA</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.cuenta}}</mat-cell>
                    </ng-container>
                    <!-- Banco -->
                    <ng-container matColumnDef="banco">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">BANCO</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.banco}}</mat-cell>
                    </ng-container>
                    <!-- Nombre -->
                    <ng-container matColumnDef="nombre">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">NOMBRE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{ element.nombre }}</mat-cell>
                    </ng-container>
                    <!-- Importe -->
                    <ng-container matColumnDef="importe">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">IMPORTE</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{transform('w-decimals', element.importe)}}
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="dataColumnsInstruments; sticky: true"></mat-header-row>
                    <mat-row class="clickeable-row" [hidden]="!(tableStatus === 'done')"
                      *matRowDef="let row; columns: dataColumnsInstruments;">
                    </mat-row>
                  </mat-table>
                  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
                    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumnsInstruments">
                    </app-table-status>
                  </div>
                  <div [hidden]="!(tableStatus === 'done')">
                    <mat-paginator [length]="dataSourceTotal" [pageSize]="tableattr.count"
                      [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePage($event)">
                    </mat-paginator>
                  </div>
                </div>
                <hr />
                <!-- Buttons -->
                <div class="text-center">
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="closeDialog()">Volver</button>
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="downloadOrdenDePagoPDF()">
                    <span *ngIf="showSpinnerOP">
                      <mat-progress-spinner mode="indeterminate" class="op-spinner" diameter="20" color="primary">
                      </mat-progress-spinner>
                    </span>
                    <span *ngIf="!showSpinnerOP">PDF Orden de Pago</span>
                  </button>
                </div>
                <!-- /Buttons -->
                <br>
              </form>
            </div>
          </mat-tab>
          <!-- /Instrumentos Tab -->
          <!-- Retenciones Tab -->
          <mat-tab label="Retenciones">
            <div id="details-form" class="box">
              <form>
                <div class="table-container">
                  <mat-table #table *ngIf="dataSource!==undefined && (dataColumnsRetentions.length !== 0)"
                    [dataSource]="dataSource">
                    <!-- Columna: 1 Fecha -->
                    <ng-container matColumnDef="fecha">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">FECHA</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.fecha}}</mat-cell>
                    </ng-container>
                    <!-- Columna: 2 Tipo de Retención -->
                    <ng-container matColumnDef="tipoRetencion">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">TIPO DE RETENCIÓN</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.tipoRetencion}}</mat-cell>
                    </ng-container>
                    <!-- Columna: 3 Nro. Retencion -->
                    <ng-container matColumnDef="nroRetencion">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">NRO. RETENCIÓN</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{ element.nroRetencion }}</mat-cell>
                    </ng-container>
                    <!-- Columna: 4 Referencia -->
                    <ng-container matColumnDef="referencia">
                      <mat-header-cell *matHeaderCellDef>
                        <span class="mat-label">REFERENCIA</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.referencia }}
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="dataColumnsRetentions; sticky: true"></mat-header-row>
                    <mat-row class="clickeable-row" [hidden]="!(tableStatus === 'done')"
                      *matRowDef="let row; columns: dataColumnsRetentions;"></mat-row>
                  </mat-table>
                  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
                    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumnsRetentions">
                    </app-table-status>
                  </div>
                </div>
                <hr />
                <!-- Buttons -->
                <div class="text-center">
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="closeDialog()">Volver</button>
                  <button type="button" class="btn btn-outline-primary col-md-3" (click)="downloadOrdenDePagoPDF()">
                    <span *ngIf="showSpinnerOP">
                      <mat-progress-spinner mode="indeterminate" class="op-spinner" diameter="20" color="primary">
                      </mat-progress-spinner>
                    </span>
                    <span *ngIf="!showSpinnerOP">PDF Orden de Pago</span>
                  </button>
                  <button type="button" class="btn btn-outline-primary col-md-3"
                    [disabled]="!(tableStatus === 'done' && dataSourceTotal > 0)" (click)="downloadRetencionesPDF()">
                    <span *ngIf="showSpinnerRetenciones">
                      <mat-progress-spinner mode="indeterminate" class="op-spinner" diameter="20" color="primary">
                      </mat-progress-spinner>
                    </span>
                    <span *ngIf="!showSpinnerRetenciones">PDF Retenciones</span>
                  </button>
                </div>
                <!-- /Buttons-->
                <br>
              </form>
            </div>
          </mat-tab>
          <!-- /Retenciones Tab -->
        </mat-tab-group>
      </div>
    </div>
  </section>
</div>