import {
  Component, Input, OnInit, Output, EventEmitter, OnChanges
} from '@angular/core';

@Component({
  selector: 'agd-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalItems: number;

  @Input() pageSize: number;

  @Output() changePage = new EventEmitter();

  pages: number;

  private _currentPage = 1;

  ngOnInit(): void {
    this.initPages();
  }

  ngOnChanges(): void {
    this.initPages();
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(page) {
    this._currentPage = page;
    this.changePage.emit({
      page: this._currentPage,
      count: this.pageSize
    });
  }

  nextPage(): void {
    this.currentPage += 1;
  }

  previuosPage(): void {
    this.currentPage -= 1;
  }

  redirectToPage(page: number) {
    this.currentPage = page;
  }

  private initPages() {
    const size = this.pageSize >= 1 ? this.pageSize : 8;
    // Math.ceil is used for rounded up (total 'consultas' / size) and calculate the total number pages 
    this.pages = Math.ceil(this.totalItems / size);
    this._currentPage = 1;
  }
}
