import {
  Component, AfterViewInit, OnDestroy, Input
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements AfterViewInit, OnDestroy {
  @Input() placeholder = 'Buscar';

  public searchControl = new UntypedFormControl();

  private inputSub: Subscription;

  @Input() searchFunction: Function = () => {};

  ngAfterViewInit() {
    this.inputSub = this.searchControl.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe(e => {
        this.searchFunction(e);
      });
  }

  ngOnDestroy() {
    if (this.inputSub) {
      this.inputSub.unsubscribe();
    }
  }
}
