import { Acopio } from '../../models/movimientos-contables/acopio.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { formatDateParam } from '../../../_shared/helpers/dates';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';
import { GridsPermissions } from 'src/app/_shared/helpers/applicationConstants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckingAccountsService {
  serviceDictionary = {
    [GridsPermissions.ULTIMOS_MOVIMIENTOS]: 'movimientos-contables/cuenta-corriente',
    [GridsPermissions.COMPROBANTES]: 'documentacion/comprobantes',
  };

  constructor(
    private http: ApiService,
    private userDataService: UserDataService
  ) { }

  /**
   * Service for Checking Accounts List
   * @param page page
   * @param count count
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listCheckingAccounts(page: number, count: number, from: string, to: string,
    orderBy: string, order: string, filters: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString())
      .set('desde', from || '')
      .set('hasta', to || '')
      .set('ordenadoPor', orderBy)
      .set('orden', order);

    params = this.http.addFilterParams(filters, params);

    return this.http.get('movimientos-contables/cuenta-corriente', params);
  }

  /**
   * Download a XLS associated with this service.
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   * @param columns columns
   * @param grid grid
   */
  downloadXls(from: string, to: string, orderBy: string, order: string, filters: any, columns: Array<string>, grid: string) {
    let params = new HttpParams()
      .set('pagina', '1')
      .set('cantidad', '1')
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('desde', from ?? '')
      .set('hasta', to ?? '')
      .set('ordenadoPor', orderBy)
      .set('orden', order);
    params = this.http.addFilterParams(filters, params);

    const xlsColumns = columns;
    params = this.http.addXlsParams(xlsColumns, params);

    return this.http.download(this.serviceDictionary[grid], params);
  }


  /**
   * Gets column list for unloads grid based on the active user's cuit
   */
  listCheckingAccountColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/cuenta-corriente', params);
  }

  /**
   * Returns 'Comprobantes' PDF.
   * @param comprobante comprobante to download
   */
  downloadComprobantePDF(comprobante: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('numero', comprobante.numero)
      .set('letra', comprobante.letra)
      .set('codigo', comprobante.codigo)
      .set('contrato', comprobante.contrato);
    if (comprobante.acopioId) {
      params = params.set('acopioId', comprobante.acopioId);
    }

    return this.http.downloadAsString('movimientos-contables/cuenta-corriente/exportar-pdf', params);
  }


  /**
   * Service for Comprobantes List
   * @param page page
   * @param count count
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listComprobantes(page: number, count: number, from: Date, to: Date,
    orderBy: string, order: string, filters: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString())
      .set('desde', from ? formatDateParam(from) : '')
      .set('hasta', to ? formatDateParam(to) : '')
      .set('ordenadoPor', orderBy)
      .set('orden', order);

    params = this.http.addFilterParams(filters, params);

    return this.http.get('documentacion/comprobantes', params);
  }

  /**
   * Gets column list for Comprobantes grid based on the active user's cuit
   */
  listComprobantesColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/comprobantes', params);
  }

  listAcopios(cuit: string, tipoSaldo: 'comercial' | 'contable'): Observable<any> {
    const params = new HttpParams()
      .set('tipoSaldo', tipoSaldo);
    return this.http.get(`movimientos-contables/cuenta-corriente/obtener-acopios/${cuit}`, params)
      .pipe(map((acopiosList: any) => {
        const response = { ...acopiosList };
        if (response.acopios) {
          response.acopios = response.acopios.map(a => new Acopio(a));
        }

        return response;
      }));
  }
}
