<div class="btn-group dropdown-input" dropdown #dropdown="bs-dropdown">
    <button
        id="button-animated"
        dropdownToggle
        type="button"
        class="btn dropdown-toggle material-icons-round text-warning"
        aria-controls="dropdown-animated"
        mat-icon-button
        [disableRipple]="true">
        <mat-icon>{{ dropdown.isOpen ? 'emoji_emotions' : 'mood'}}</mat-icon>
    </button>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu">
      <li role="menuitem" (click)="keepOpen && $event.stopPropagation()">
        <emoji-mart
            [sheetSize]="32"
            [showPreview]="false"
            [emojiSize]="20"
            [isNative]="true"
            [enableSearch]="false"
            [exclude]="['custom', 'flags', 'recent']"
            [showSingleCategory]="true"
            (emojiSelect)="addEmoji($event)">
        </emoji-mart>
      </li>
    </ul>
</div>
<!-- preload component -->
<emoji-mart [hidden]="true"></emoji-mart>
