import {
  UntypedFormGroup, ValidationErrors, UntypedFormControl, FormGroupDirective, NgForm,
  AbstractControl
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Custom form validator that determines if at least one controller of the group has a value.
 * @returns A new validator function that returns error atLeastOneSelected: true if none of the controllers has a value,
 *  otherwise returns null
 */
export function emptyGroupValue() {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    const hasSomeValue = group?.controls
      && Object.keys(group.controls).some((k) => {
        const { value } = group.controls[k];
        return (Array.isArray(value) && value.length) || (!Array.isArray(value) && value);
      });
    return hasSomeValue ? null : { emptyGroupValue: true };
  };
}

/**
 * Custom form validator that determines if at least one controller of the group has a value.
 * @returns A new validator function that returns error atLeastOneSelected: true if none of the controllers has a value,
 *  otherwise returns null
 */
export function atLeastOneSelected() {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    const hasAtLeastOne = group?.controls
      && Object.keys(group.controls).some((k) => group.controls[k].value);
    return hasAtLeastOne ? null : { atLeastOneSelected: true };
  };
}

/*
 * Custom form validator that evaluates if the size of the selected file is equal or less than maxFileSize.
 * @param maxFileSize max file size value in MB.
 * @returns A new validator function that returns error maxFileSize: true if the value exceeded the max file size,
 * otherwise returns null
 */
export function fileSizeValidator(maxFileSize: number) {
  return (control: UntypedFormControl) => {
    const file = control.value;

    if (!file || !(file instanceof File)) return null;

    const fileSize = file.size;

    if (fileSize > maxFileSize * 1024 ** 2) {
      return {
        maxFileSize: true,
      };
    }

    return null;
  };
}

export interface Resolution { x: number; y: number }
/**
 * Custom form validator that evaluates if the resolution of an image is equal or less than maxResolution.
 * @param maxResolution object with max values for width and height
 * @returns A new validator function that returns error maxResolution: true if the image exceeded the max resolution size,
 * otherwise returns null
 */
export function fileResolutionValidator(maxResolution: Resolution) {
  return (control: UntypedFormControl) => {
    const file = control.value;

    if (!file || !(file instanceof File)) return Promise.resolve(null);

    if (!file.type.startsWith('image')) return Promise.resolve(null);

    return new Promise<{ maxResolution: boolean } | null>((resolve) => {
      const selectedFile = new FileReader();

      selectedFile.onload = (event) => {
        const url = event.target.result as string;
        const image = new Image();

        image.onload = () => {
          if (image.width > maxResolution.x || image.height > maxResolution.y) {
            resolve({ maxResolution: true });
          } else {
            resolve(null);
          }
        };

        image.onerror = () => {
          resolve(null);
        };

        image.src = url;
      };

      selectedFile.onerror = () => {
        resolve(null);
      };

      selectedFile.readAsDataURL(file);
    });
  };
}

/**
 * Evaluates if the selected file has a valid type
 * @param formats multiline string with the different valid mime types separated by ","
 * @returns invalidFormat error if the file is not included in the list
 */
export function fileFormatValidator(formats: string) {
  const formatsList = formats?.split(',').map(x => x.replace('\n', '').trim()) || [];
  return (control: UntypedFormControl) => {
    const file = control.value;

    if (!file || !(file instanceof File)) return null;

    const mimeType = file.type;

    if (!formatsList.includes(mimeType)) {
      return {
        invalidFormat: true,
      };
    }

    return null;
  };
}

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private customControl: UntypedFormControl) { }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.customControl?.touched && this.customControl?.invalid;
  }
}

/**
 * Sets the conditionalRequired if the other controller doesn't have a value, both controllers need to be in the same form group
 * @param conditionalControllerName name of the controller it depends on
 * @returns conditionalRequired error
 */
export function conditionalRequired(conditionalControllerName: string) {
  return (control: UntypedFormControl): ValidationErrors | undefined => {
    if (!(conditionalControllerName && control.parent)) return undefined;

    const ctrl = control.parent.get(conditionalControllerName);

    if (!ctrl) return undefined;

    const required = ctrl.value != null && control.value == null;

    return required ? { conditionalRequired: true } : undefined;
  };
}

/**
 * Validate if a string is valid as url
 * @param control
 * @returns result of validation
 */

export function urlValidator(control: AbstractControl): ValidationErrors | null {
  const urlValue = control.value;
  // return null if value is not set
  if (!urlValue) {
    return null;
  }
  try {
    // Try to create a URL instance from the input value
    const url = new URL(urlValue);
    // validate http or https protocol
    if (!url.origin.startsWith('http://') && !url.origin.startsWith('https://')) {
      throw new Error();
    }
    return url;
  } catch (err) {
    // if error happend, url is invalid
    return { invalid: true };
  }
}
