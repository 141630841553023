<div *ngIf="headerData?.hasDropDown" class="btn-group" dropdown>
    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle boton"
        aria-controls="dropdown-basic">{{headerData.name}}
    </button>
    <div>
        <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem">
                <a class="dropdown-item" (click)="orderTable(headerData.orderBy,'asc')">
                    <i class="icofont icofont-arrow-up"></i>
                    Orden ascendente
                </a>
            </li>
            <li role="menuitem">
                <a class="dropdown-item" (click)="orderTable(headerData.orderBy,'desc')">
                    <i class="icofont icofont-arrow-down"></i>
                    Orden descendente
                </a>
            </li>
        </ul>
    </div>
</div>
<ng-container *ngIf="!headerData?.hasDropDown">
    {{headerData.name}}
</ng-container>