export class Acopio {
  id: number;

  nombre: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.nombre = obj.nombre;
  }
}
