<!-- Filters Container -->
<div class="filters-wrap">

  <!-- Filter Toogle -->
  <div class="filter-header">
    <agd-filters-toggle [filters]="filters" (filterValueChange)="applyFilters($event)">
    </agd-filters-toggle>

    <!-- Download Excel -->
    <i class="icofont-cloud-download" [hidden]="showSpinner" (click)="downloadXls()" title="Descargar Excel"
      data-toggle="tooltip" data-placement="right">
    </i>
    <i [hidden]="!showSpinner">
      <mat-progress-spinner mode="indeterminate" diameter="19"></mat-progress-spinner>
    </i>
  </div>

  <!-- Filter Anchor -->
  <agd-filters-toolbar-anchor></agd-filters-toolbar-anchor>

</div>

<!-- Section KPI's -->
<ul class="metrics row">

  <li *ngIf="columnExists('kgPactado')">
    <div class="metric-wrap">
      <h4>KG PACTADOS</h4>
      <h3>{{ totalKgPactado| formatNumber }}</h3>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiAgreedWidth+'%'" aria-valuenow="kpiAgreedWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('kgEntregados')">
    <div class="metric-wrap">
      <h4>KG ENTREGADOS</h4>
      <h3>{{ totalKgEntregados | formatNumber}}</h3>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiDeliveredWidth+'%'"
          aria-valuenow="kpiDeliveredWidth" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('kgPendientes')">
    <div class="metric-wrap">
      <h4>KG PENDIENTES</h4>
      <h3>{{ totalKgPendientes | formatNumber}}</h3>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiPendingWidth+'%'" aria-valuenow="kpiPendingWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('kgFijados')">
    <div class="metric-wrap">
      <h4>KG FIJADOS</h4>
      <h3>{{ totalKgFijados | formatNumber}}</h3>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiSetWidth+'%'" aria-valuenow="kpiSetWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('kgAFijar')">
    <div class="metric-wrap">
      <h4>KG A FIJAR</h4>
      <h3>{{ totalKgAFijar | formatNumber}}</h3>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiToSetWidth+'%'" aria-valuenow="kpiToSetWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

</ul>

<!-- Contenedor Tabla -->
<div class="main-table">
  <div class="mat-table-container">

    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource!==undefined && (dataColumns.length !== 0)">

      <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
          <agd-table-column-header [headerData]="headerColumns[i]"
            (changeOrder)="orderTable($event.column, $event.order)">
          </agd-table-column-header>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- Link para Nro CP -->
          <a *ngIf="headerColumns[i].filter === 'kgEntregados'" href="javascript:void(0)"
            (click)="viewRomaneoDetails(element.nroContrato || element.parteDeVenta)">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <div (click)="viewDetails(element)" *ngIf="headerColumns[i].filter !== 'kgEntregados'">
            {{transform(headerColumns[i].format,element[column])}}
          </div>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <div *ngIf="headerColumns[i].total !== undefined">
            <div>
              {{getColumnTotal(headerColumns[i].total)| formatNumber}}
            </div>
          </div>
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns; sticky: true"></mat-header-row>
      <mat-row class="hovereable-row" tabindex="1" [hidden]="!(tableStatus === 'done')"
        *matRowDef="let row; columns: dataColumns;"></mat-row>
      <mat-footer-row [class.table-footer-hidden]="tableStatus !== 'done'"
        *matFooterRowDef="dataColumns"></mat-footer-row>
    </mat-table>
  </div>

  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumns"></app-table-status>
  </div>

  <div [hidden]="!(tableStatus === 'done')" *ngIf="dataColumns.length !== 0">
    <mat-paginator [length]="dataSourceTotal" [pageSize]="tableattr.count" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="handlePage($event)">
    </mat-paginator>
  </div>

</div>

<div class="grid-footer-label-container" [ngClass]="{ invisible: !(tableStatus === 'done') }">
  <div class="alert" role="alert">
    <span>Última actualización: {{ fechaUltimaActualizacionDatos | date: 'dd/MM/yy HH:mm' }}</span>
  </div>
</div>