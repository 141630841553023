<!-- Filters Container -->
<div class="filters-wrap">
  <!-- Filter Toogle -->
  <div class="filter-header">
    <agd-filters-toggle [filters]="filters" (filterValueChange)="applyFilters($event)">
    </agd-filters-toggle>

    <!-- Download Excel -->
    <i class="icofont-cloud-download" [hidden]="showSpinner" (click)="downloadXls()" title="Descargar Excel"
      data-toggle="tooltip" data-placement="right">
    </i>
    <i [hidden]="!showSpinner">
      <mat-progress-spinner mode="indeterminate" diameter="19"></mat-progress-spinner>
    </i>
  </div>
  <!-- Filter Anchor -->
  <agd-filters-toolbar-anchor></agd-filters-toolbar-anchor>
</div>

<!-- Section Metricas -->
<ul class="metrics">

  <!-- KG APLICADOS -->
  <li *ngIf="columnExists('kgAplicados')">
    <div class="metric-wrap">
      <app-totals-status [totalsStatus]="totalsStatus" [totalValue]="totalKgAplicados"
        [totalTitle]="totalKgAplicadosTitle" [totalFormat]="'wo-decimals'">
      </app-totals-status>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiAplicadosWidth+'%'"
          aria-valuenow="kpiAplicadosWidth" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

</ul>

<!-- Contenedor Tabla -->
<div class="main-table">
  <div class="mat-table-container">
    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource!==undefined && (dataColumns.length !== 0)">
      <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
          <agd-table-column-header [headerData]="headerColumns[i]"
            (changeOrder)="orderTable($event.column, $event.order)">
          </agd-table-column-header>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- Link para Ticket-->
          <a *ngIf="headerColumns[i].filter === 'ticket'" (click)="viewRomaneoDetails(element.ticket, 'ticket')">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <!-- Link para Numero de Contrato-->
          <a *ngIf="headerColumns[i].filter === 'parteDeVenta'"
            (click)="viewContractDetails(element.parteDeVenta, element.nroCegLsg)">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <a *ngIf="headerColumns[i].filter === 'nroContrato'"
            (click)="viewContractDetails(element.nroContrato, element.nroCegLsg)">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <!-- Link para Nro CP -->
          <a *ngIf="headerColumns[i].filter === 'nroCP'" (click)="viewRomaneoDetails(element.nroCP, 'nroCP')">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <div (click)="viewDetails(element)" *ngIf="headerColumns[i].filter !== 'ticket'
          && headerColumns[i].filter !== 'nroCP'
          && headerColumns[i].filter !== 'nroContrato'
          && headerColumns[i].filter !== 'parteDeVenta'">{{transform(headerColumns[i].format,element[column])}}</div>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <div *ngIf="headerColumns[i].total !== undefined">
            <img *ngIf="totalsStatus !== 'done'" src="/assets/images/spinner-dots.gif" width="16" alt="" />
            <div *ngIf="totalsStatus === 'done'">
              {{getColumnTotal(headerColumns[i].total)| formatNumber }}
            </div>
          </div>
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns; sticky: true"></mat-header-row>
      <mat-row class="hovereable-row" tabindex="1" [hidden]="!(tableStatus === 'done')"
        *matRowDef="let row; columns: dataColumns;"></mat-row>
      <mat-footer-row [class.table-footer-hidden]="tableStatus !== 'done'"
        *matFooterRowDef="dataColumns"></mat-footer-row>
    </mat-table>
  </div>

  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumns"></app-table-status>
  </div>

  <div [hidden]="!(tableStatus === 'done')" *ngIf="dataColumns.length !== 0">
    <mat-paginator [length]="dataSourceTotal" [pageSize]="tableattr.count" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="handlePage($event)">
    </mat-paginator>
  </div>

</div>

<div class="grid-footer-label-container" [ngClass]="{ invisible: !(tableStatus === 'done') }">
  <div class="alert" role="alert">
    <span>Última actualización: {{ fechaUltimaActualizacionDatos | date: 'dd/MM/yy HH:mm' }}</span>
  </div>
</div>