<div class="dropzone" (mouseenter)="rippleOn()" (mouseleave)="rippleOff()" appDnD (fileDragOver)="rippleOn()"
  (fileDragLeave)="rippleOff()" (fileDropped)="handleFile($event)">
  <input type="file" #fileDropRef [accept]="acceptTypes" id="fileDropRef" (change)="handleFile($event)" />
  <label for="fileDropRef" matRipple [matRippleTrigger]="fileDropRef" [matRippleColor]="rippleColor"
    [title]="selected?.name">
    <span *ngIf="!selected">Presiona o arrastra para agregar un archivo.</span>
    <ng-container *ngIf="selected">
      <span class="icon material-icons-outlined">
        attachment
      </span>
      <span class="flex-grow-1">{{ selected.name }}</span>
      <button class="delete-button" mat-icon-button (click)="clearSelection($event)" title="Borrar">
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </ng-container>
  </label>
</div>