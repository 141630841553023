import { GridsPermissions, ModulesPermissions } from 'src/app/_shared/helpers/applicationConstants';
import {
  Component, OnDestroy, OnInit, ViewChild, ElementRef
} from '@angular/core';
import { ProductsService } from 'src/app/_core/services/products/products.service';
import { HarvestsService } from 'src/app/_core/services/harvests/harvests.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ContractsService } from 'src/app/_core/services/contracts/contracts.service';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { Subject } from 'rxjs';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { capitalize, exportXLS } from 'src/app/_shared/helpers/fileHelper';
import { ActivatedRoute } from '@angular/router';

import {
  completeSubscription,
  fillGrid,
  getColumnTotal,
  getRowDetails,
  resetPaginator,
  userTooltips,
} from 'src/app/_shared/helpers/listRole';
import { takeUntil } from 'rxjs/operators';
import { RowDetailsChangedService } from 'src/app/_core/services/common/row-details-changed/row-details-changed.service';
import { PaymentsService } from 'src/app/_core/services/payments/payments.service';
import { TotalsStatusEnum } from '../totals-status/totals-status.enum';
import { FiltersConfig } from 'src/app/_shared/modules/agd-components/filters/models/filters.types';
import { FiltersService } from 'src/app/_shared/modules/agd-components/filters/services/filters.service';
import { DataListBaseClass } from 'src/app/_modules/pages/classes/data-list.abstract';

@Component({
  selector: 'app-contracts-page',
  templateUrl: './contracts-page.component.html',
  styleUrls: ['./contracts-page.component.scss'],
  })
export class ContractsPageComponent extends DataListBaseClass implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatTable, { read: ElementRef }) table: ElementRef;

  dataSource: any;

  dataSourceTotal = 0;

  filters: FiltersConfig;

  totalKgVendidos = 0;

  totalKgAplicados = 0;

  totalKgPendientes = 0;

  totalSaldoPesos = 0;

  kpiAppliedWidth = 0;

  kpiPendingWidth = 0;

  kpiBalanceWidth = 0;

  kpiSeltWidth = 0;

  fechaUltimaActualizacionDatos = null;

  totalKgVendidosTitle = 'KG VENDIDOS';

  totalKgAplicadosTitle = 'KG APLICADOS';

  totalKgPendientesTitle = 'KG PENDIENTES';

  totalSaldoPesosTitle = 'SALDO EN $';

  headerColumns = [];

  /**
   * Show spinner during download process.
   */
  showSpinner: boolean;

  /**
   * Used for possible fields based on role of user.
   */
  possibleFields = {
    fecha: {
      type: 'date',
      filterName: 'fecha',
      format: 'date',
      hasDropDown: true,
    },
    parteDeVenta: {
      type: 'number',
      filterName: 'parteDeVenta',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
      key: ''
    },
    fechaContrato: {
      type: 'date',
      filterName: 'fechaContrato',
      format: 'date',
      hasDropDown: true,
    },
    fechaVencimiento: {
      filterName: 'fechaVencimiento',
      key: 'fechaVencimiento',
      filterFrom: 'fechaVencimientoDesde',
      filterTo: 'fechaVencimientoHasta',
      type: 'expirationDate',
      hasDropDown: true,
      format: 'date',
      // If set to true, the format property won't be considered when trying to apply it in the badges filter list
      skipFormattingForBadge: true,
    },
    nroContrato: {
      type: 'number',
      filterName: 'nroContrato',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
      key: ''
    },
    nroContratoAFijar: {
      type: 'number',
      filterName: 'nroContratoAFijar',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
      key: 'input',
    },
    producto: {
      data: [],
      key: 'select',
      filterName: 'producto',
      hasDropDown: true,
    },
    cosecha: {
      data: [],
      key: 'select',
      filterName: 'cosecha',
      hasDropDown: true,
    },
    negocio: {
      type: 'text',
      key: 'input',
      filterName: 'negocio',
      hasDropDown: true,
    },
    precioPesos: {
      type: 'number',
      filterName: 'precioPesos',
      hasDropDown: true,
      format: 'w-decimals',
    },
    precioPesosOtros: {
      type: 'number',
      filterName: 'precioPesosOtros',
      hasDropDown: true,
      format: 'w-decimals',
    },
    precioUsd: {
      type: 'number',
      filterName: 'precioUsd',
      hasDropDown: true,
      format: 'w-decimals',
    },
    flete: {
      type: 'number',
      filterName: 'flete',
      hasDropDown: true,
      format: 'w-decimals',
    },
    precioUsdOtros: {
      type: 'number',
      filterName: 'precioUsdOtros',
      hasDropDown: true,
      format: 'w-decimals',
    },
    kgVendidos: {
      type: 'kg',
      filterFrom: 'kgVendidosDesde',
      filterTo: 'kgVendidosHasta',
      filterName: 'kgVendidos',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalKgVendidos',
      key: ''
    },
    kgAplicados: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgAplicadosDesde',
      filterTo: 'kgAplicadosHasta',
      filterName: 'kgAplicados',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalKgAplicados',
    },
    kgPendientes: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgPendientesDesde',
      filterTo: 'kgPendientesHasta',
      filterName: 'kgPendientes',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalKgPendientes',
    },
    saldoPesos: {
      type: 'input',
      filterName: 'saldoPesos',
      hasDropDown: false,
      format: 'w-decimals',
    },
    deposito: {
      type: 'text',
      filterName: 'deposito',
      hasDropDown: true,
      key: ''
    },
    corredor: {
      type: 'input',
      filterName: 'corredor',
      hasDropDown: true,
      key: ''
    },
    vendedor: {
      data: [],
      type: 'input',
      filterName: 'vendedor',
      hasDropDown: true,
      key: ''
    },
  };

  dataColumns: Array<string> = [];

  tableStatus: TableStatusEnum = TableStatusEnum.LOADING;

  totalsStatus: TotalsStatusEnum = TotalsStatusEnum.LOADING;

  tableattr: any = {
    filters: {},
    from: '',
    to: '',
    order: '',
    orderBy: '',
    search: '',
    count: 10,
    page: 1,
    status: '',
  };

  private XLSFileName = `${capitalize(this.getModule())} - ${capitalize(this.getGrid())}`;

  /**
   * Used to check if this is the first time we're running the permissionsChanged function since
   * we loaded this component.
   */
  private firstLoad = true;

  constructor(
    private route: ActivatedRoute,
    productsService: ProductsService,
    harvestService: HarvestsService,
    private contractsService: ContractsService,
    private paymentService: PaymentsService,
    // Service that allows to suscribe to cuit changes
    private cuitChangedService: CuitChangedService,
    // Service used for updating active user
    private userDataService: UserDataService,
    // used in order to emit row data.
    private rowDetailsChangedService: RowDetailsChangedService,
    private filtersService: FiltersService,
  ) {
    super(productsService, harvestService);
  }

  /**
   * If user has grid permission, we fill it.
   */
  initializeColumns() {
    if (this.userDataService.hasGridPermission(this.getModule(), this.getGrid())) {
      fillGrid(this, this.getService(), this.getServiceMethod(), 'getContracts', 'getContractsTotals');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  /**
   * Update grid based on applied filters.
   */
  updateContracts() {
    this.ngUnsubscribe = new Subject();
    this.resetKpis();
    resetPaginator(this);
    this.getContracts();
    this.getContractsTotals();
  }

  /**
   * Reset KPI's values.
   */
  resetKpis() {
    this.totalKgAplicados = 0;
    this.totalKgPendientes = 0;
    this.totalKgVendidos = 0;
    this.totalSaldoPesos = 0;
    this.kpiAppliedWidth = 0;
    this.kpiBalanceWidth = 0;
    this.kpiPendingWidth = 0;
    this.kpiSeltWidth = 0;
  }

  /**
   * Returns module of the current url in order to check permissions.
   */
  getModule(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? ModulesPermissions.VENTAS : ModulesPermissions.CONTRATOS;
  }

  /**
   * Returns module of the current url in order to check permissions.
   */
  getGrid(): string {
    return GridsPermissions.CONTRATOS;
  }

  /**
   * Returns service to use based on url.
   */
  getService(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? 'paymentService' : 'contractsService';
  }

  /**
   * Returns service method to use based on url.
   */
  getServiceMethod(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? 'listSalesColumns' : 'listContractsColumns';
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.cuitChangedService.activeCuitChanged.subscribe((cuit) => this.cuitChanged(cuit));
    this.subs.sink = this.cuitChangedService.permissionsChanged.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.permissionsChanged();
        }
      }
    );
    userTooltips();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.completeSubscription();
  }

  /**
   * Returns column's total
   * @param column column to show total
   */
  getColumnTotal(column) {
    return getColumnTotal(this, column);
  }

  /**
   * Get list of Contracts
   */
  getContracts() {
    this.tableStatus = TableStatusEnum.LOADING;
    const {
      fecha, fechaVencimiento, ...filters
    } = this.tableattr.filters || {};

    const [fechaVencimientoDesde, fechaVencimientoHasta] = this.filtersService.parseDateFromToQuery(fechaVencimiento);

    const [from, to] = this.filtersService.parseDateFromToQuery(fecha);

    if (fechaVencimientoDesde) {
      filters.fechaVencimientoDesde = fechaVencimientoDesde;
    }

    if (fechaVencimientoHasta) {
      filters.fechaVencimientoHasta = fechaVencimientoHasta;
    }

    this.contractsService
      .listContracts(
        this.tableattr.page,
        this.tableattr.count,
        from,
        to,
        this.tableattr.orderBy,
        this.tableattr.order,
        filters
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          const contratos = res.contratos.map((x) => ({
            ...x,
            fechaVencimientoContrato: x.fechaVencimiento
          }));

          this.dataSource = new MatTableDataSource(contratos);
          this.dataSourceTotal = res.cantidadTotal;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          if (this.tableattr.page > 0) {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos || this.fechaUltimaActualizacionDatos;
          } else {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos;
          }
        },
        (err: any) => {
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Sets active cuit and clears grid.
   * @param cuit cuit
   */
  cuitChanged(cuit: string) {
    this.completeSubscription();
    this.userDataService.setActiveCUIT(cuit);
  }

  /**
   * Get 'Contratos' totals.
   */
  getContractsTotals() {
    this.totalsStatus = TotalsStatusEnum.LOADING;
    const {
      fecha, fechaVencimiento, ...filters
    } = this.tableattr.filters || {};

    const [fechaVencimientoDesde, fechaVencimientoHasta] = this.filtersService.parseDateFromToQuery(fechaVencimiento);

    const [from, to] = this.filtersService.parseDateFromToQuery(fecha);

    if (fechaVencimientoDesde) {
      filters.fechaVencimientoDesde = fechaVencimientoDesde;
    }

    if (fechaVencimientoHasta) {
      filters.fechaVencimientoHasta = fechaVencimientoHasta;
    }
    this.contractsService
      .listContractsTotals(from, to, filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.totalKgAplicados = res.totalKgAplicados;
          this.totalKgPendientes = res.totalKgPendientes;
          this.totalKgVendidos = res.totalKgVendidos;
          this.totalSaldoPesos = res.totalSaldoPesos;
          this.setKpis();
          this.totalsStatus = TotalsStatusEnum.DONE;
        },
        (err: any) => {
          console.error(err);
          this.totalsStatus = TotalsStatusEnum.ERROR;
        }
      );
  }

  /**
   * Handle Paginator event
   * @param e event
   */
  handlePage(e: any) {
    this.tableattr.page = e.pageIndex + 1;
    this.tableattr.count = e.pageSize;
    this.getContracts();
  }

  /**
   * Method that takes column and order selected and applies sorting
   * @param column name of the selected column
   * @param order order selected (asc or des)
   */
  orderTable(column: string, order: string) {
    if (!(this.tableattr.orderBy === column && this.tableattr.order === order)) {
      this.tableattr.orderBy = column;
      this.tableattr.order = order;
      if (this.totalsStatus === TotalsStatusEnum.LOADING) {
        this.completeSubscription();
        this.updateContracts();
      } else {
        this.completeSubscription();
        this.ngUnsubscribe = new Subject();
        this.getContracts();
      }
    }
  }

  /**
   * Unsubscribes all pending requests.
   */


  /**
   * Sets all grid's kpis.
   */
  setKpis() {
    if (this.totalKgVendidos > 0) {
      this.kpiAppliedWidth = (this.totalKgAplicados * 100) / this.totalKgVendidos;
      this.kpiPendingWidth = (this.totalKgPendientes * 100) / this.totalKgVendidos;
      this.kpiSeltWidth = 100;
    } else {
      this.kpiAppliedWidth = this.totalKgAplicados > 0 ? 100 : 0;
      this.kpiPendingWidth = this.totalKgPendientes > 0 ? 100 : 0;
    }
    this.kpiBalanceWidth = this.totalSaldoPesos > 0 ? 100 : 0;
  }

  applyFilters(filtersValue: Record<string, string>) {
    // If it's not the first load of the 'consultas' apply filters and getComplaints method
    if (!this.firstLoad) {
      this.tableattr.filters = filtersValue;

      this.completeSubscription();
      this.updateContracts();
    }
  }

  /**
   * Calls the XLS service to get the download link.
   */
  downloadXls() {
    this.showSpinner = true;
    const { fecha, fechaVencimiento, ...filters } = this.tableattr.filters;
    const [from, to] = this.filtersService.parseDateFromToQuery(fecha);
    const [fechaVencimientoDesde, fechaVencimientoHasta] = this.filtersService.parseDateFromToQuery(fechaVencimiento);
    if (fechaVencimientoDesde) {
      filters.fechaVencimientoDesde = fechaVencimientoDesde;
    }

    if (fechaVencimientoHasta) {
      filters.fechaVencimientoHasta = fechaVencimientoHasta;
    }
    this.subs.sink = this.contractsService.downloadXls(
      from,
      to,
      this.tableattr.orderBy,
      this.tableattr.order,
      filters,
      this.dataColumns
    ).subscribe(
      (res: any) => {
        exportXLS(res, this.XLSFileName);
        this.showSpinner = false;
      },
      (err: any) => {
        console.error(err);
        this.showSpinner = false;
      }
    );
  }

  /**
   * Goes to contract details page
   * @param nroContrato contract number
   */
  viewContractDetails(nroContrato: any) {
    this.router.navigate([this.transform('wo-decimals-or-separators', nroContrato), 'detalle'], {
      relativeTo: this.route,
      state: { redirectTo: `${this.getUrlModule()}/${this.getGrid()}` },
    });
  }

  /**
   * Goes to 'Aplicaciones' page
   * @param filterValue value
   */
  viewAplicacionesDetails(filterValue: any) {
    const filterName = this.getModule() === ModulesPermissions.CONTRATOS ? 'nroContrato' : 'parteDeVenta';
    this.navigateWithFilter([`${this.getUrlModule()}/aplicaciones`], filterName, filterValue);
  }

  /**
   * Goes to 'Contratos a Fijar' page
   * @param filterValue value
   */
  viewContratoAFijarDetails(filterValue: any) {
    const filterName = this.getModule() === ModulesPermissions.CONTRATOS ? 'nroContrato' : 'parteDeVenta';
    this.navigateWithFilter([`${this.getUrlModule()}/contratos-a-fijar`], filterName, filterValue);
  }

  /**
   * Returns module of the current url in order to check permissions.
   */
  getUrlModule(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? 'ventas' : 'contratos';
  }

  columnExists(name: string) {
    if (!this.dataSource) {
      return false;
    }

    return !!this.dataColumns.find((column) => column === name);
  }

  /**
   * Emit an event in order to show row details.
   * @param row row data.
   */
  viewDetails(row: any) {
    const data = {
      rowData: getRowDetails(this.headerColumns, row, this.dataColumns),
    };
    this.rowDetailsChangedService.rowDetailsChanged(data);
  }

  protected getFiltersConfig(): FiltersConfig {
    return {
      fecha: {
        label: 'Rango de Fecha',
        type: 'date-range',
      },
      ...(this.getModule() === ModulesPermissions.CONTRATOS && {
        nroContrato: {
          label: 'Nro de Contrato',
          type: 'number',
          format: 'wo-decimals-or-separators'
        },
      }),
      ...(this.getModule() === ModulesPermissions.VENTAS && {
        parteDeVenta: {
          label: 'Parte de Venta',
          type: 'number',
          format: 'wo-decimals-or-separators'
        },
      }),
      nroContratoAFijar: {
        label: 'Nro de Contrato Fijar',
        type: 'number',
        format: 'wo-decimals-or-separators'
      },
      producto: {
        label: 'Producto',
        type: 'radio',
        options: this.filtersOptions.producto,
      },
      cosecha: {
        label: 'Cosecha',
        type: 'radio',
        options: this.filtersOptions.cosecha,
      },
      negocio: {
        label: 'Negocio',
        type: 'text',
      },
      ...(this.getModule() === ModulesPermissions.CONTRATOS && {
        kgVendidos: {
          label: 'Kg Vendidos',
          type: 'number-range',
          fromAttr: 'kgVendidosDesde',
          toAttr: 'kgVendidosHasta',
          format: 'w-decimals',
        },
      }),
      kgAplicados: {
        label: 'Kg Aplicados',
        type: 'number-range',
        fromAttr: 'kgAplicadosDesde',
        toAttr: 'kgAplicadosHasta',
        format: 'w-decimals',
      },
      ...(this.getModule() === ModulesPermissions.VENTAS && {
        kgPendientes: {
          label: 'Kg Pendientes',
          type: 'number-range',
          fromAttr: 'kgPendientesDesde',
          toAttr: 'kgPendientesHasta',
          format: 'w-decimals',
        },
      }),
      ...(this.getModule() === ModulesPermissions.CONTRATOS && {
        producto: {
          label: 'Producto',
          type: 'radio',
          options: this.filtersOptions.producto,
        },
        cosecha: {
          label: 'Cosecha',
          type: 'radio',
          options: this.filtersOptions.cosecha,
        },
        negocio: {
          label: 'Negocio',
          type: 'text',
        },
        kgPendientes: {
          label: 'Kg Pendientes',
          type: 'number-range',
          fromAttr: 'kgPendientesDesde',
          toAttr: 'kgPendientesHasta',
          format: 'w-decimals',
        },
        deposito: {
          type: 'text',
          label: 'Deposito',
        },
        corredor: {
          type: 'text',
          label: 'Corredor',
        },
        vendedor: {
          type: 'text',
          label: 'Vendedor',
        },
      }),
      fechaVencimiento: {
        label: 'Fecha de Vencimiento',
        type: 'date-range',
      },
    };
  }

  /**
   * Sets permissions and refresh menu
   */
  private permissionsChanged() {
    this.ngUnsubscribe = new Subject();
    // If this is the first time we called this function in this component's life, then be
    // careful not to reset the filters since it could happen they've been set on the constructor.
    if (!this.firstLoad) {
      this.tableattr = {
        filters: {},
        from: '',
        to: '',
        order: '',
        orderBy: '',
        search: '',
        count: 10,
        page: 1,
        status: '',
      };
    }
    this.firstLoad = false;
    this.initializeColumns();
  }

  private completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }
}
