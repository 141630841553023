import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'agd-filters-toolbar-anchor',
  templateUrl: './filters-toolbar-anchor.component.html',
  styleUrls: ['./filters-toolbar-anchor.component.scss']
})
export class FiltersToolbarAnchorComponent implements OnInit, OnDestroy {
  constructor(
    private filtersService: FiltersService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.filtersService.addToolbarAnchor(this.viewContainerRef);
  }

  ngOnDestroy(): void {
    this.filtersService.removeToolbarAnchor(this.viewContainerRef);
  }
}
