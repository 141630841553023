import {
  animate, state, style, transition, trigger
} from '@angular/animations';

const AppAnimationTime = '200ms';

export class AppAnimations {
  /**
   * Animate element on entering the DOM.
   * From right -> left on 'fordward' and left -> right on 'backward' state.
   */
  static readonly fordwardBackward = trigger('fordwardBackward', [
    transition('void => backward', [
      style({
        transform: 'translateX(-100%)',
        zIndex: 2
      }),
      animate(`${AppAnimationTime} ease-out`, style({ transform: 'translate(0)', zIndex: 2 })),
    ]),
    transition('void => fordward', [
      style({
        transform: 'translateX(100%)',
        zIndex: 2,
      }),
      animate(`${AppAnimationTime} ease-out`, style({ transform: 'translateX(0)', zIndex: 2 })),
    ]),
  ]);

  /**
   * Block initial animation on entering the DOM.
   */
  static readonly blockInitialEnter = trigger('blockInitialEnter', [
    transition(':enter', [])
  ]);

  static readonly fadeIn = trigger('fadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('400ms ease-in-out', style({ opacity: 1 }))
    ])
  ]);

  static readonly fadeOut = trigger('fadeOut', [
    transition(':leave', [
      style({ opacity: 1 }),
      animate('400ms ease-in-out', style({ opacity: 0 }))
    ])
  ]);

  static readonly expand = trigger('expand', [
    state('collapsed, void', style({
      height: '0px',
      minHeight: '0',
      padding: '0',
      margin: '0',
    })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> void, expanded <=> collapsed', animate(`${AppAnimationTime} ease-in`)),
  ]);
}
