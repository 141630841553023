<label>{{label}}: 
    <label *ngIf="maxValue">{{percentage}}% ({{value}}/{{maxValue}})</label>
    <label *ngIf="!maxValue"> - </label>
</label>
<div class="progress">
    <div class="progress-bar"
        [ngClass]="{ 'progress-bar-striped progress-bar-animated': loading }"
        [ngStyle]="{ 'background-color': color, width: percentage + '%' }"
        role="progressbar"
        [attr.aria-valuenow]="percentage"
        aria-valuemin="0"
        aria-valuemax="100">
    </div>
</div>
