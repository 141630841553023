import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

type orderOptions = 'asc' | 'desc';

interface ChangeOrderEvent {
  column: string;
  order: orderOptions;
}

@Component({
  selector: 'agd-table-column-header',
  templateUrl: './table-column-header.component.html',
  styleUrls: ['./table-column-header.component.scss']
  })
export class TableColumnHeaderComponent {
  @Input() headerData: any;

  @Output() changeOrder = new EventEmitter<ChangeOrderEvent>();

  orderTable(column: string, order: orderOptions) {
    this.changeOrder.emit({ column, order });
  }
}
