import { SubSink } from 'subsink';
import { FiltersConfig, FiltersValues } from '../../models/filters.types';
import { FiltersService } from '../../services/filters.service';
import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import { DateRangeHeaderComponent } from '../date-range-header/date-range-header.component';

// Base class for filter components
@Component({
  template: ''
  })
export abstract class FiltersAbstract implements OnInit, OnDestroy {
  // Used to display the current configuration
  config: FiltersConfig;

  values: FiltersValues = {};

  clearable = false;

  _dateRangeHeader = DateRangeHeaderComponent;

  protected _currentValues: FiltersValues = {};

  protected subs = new SubSink();

  constructor(protected filtersService: FiltersService) { }

  ngOnInit(): void {
    // Subscribe to filter configuration changes
    this.subs.sink = this.filtersService.filtersConfig.subscribe(value => {
      this.config = value;
    });

    // Subscribe to values updates
    this.subs.sink = this.filtersService.filtersValues.subscribe(value => {
      this.values = value;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  updateModelValue(key: string, value: any) {
    this.initFilterRecord(key, value);
    this._currentValues[key].applyDisabled = this._currentValues[key].value == null;
  }

  getNumberRangeValue(key: string, attr: 'from' | 'to') {
    let val: number;
    const currentValue = this._currentValues?.[key]?.value;
    const filterConfig = this.config[key];

    if (filterConfig.type === 'number-range') {
      const index = this.filtersService.getCustomIndexValue(filterConfig, attr);
      val = currentValue?.[index];
    }

    return val;
  }

  clearFilters(key?: string) {
    this.filtersService.clearFilters(key);
  }

  protected onNumberRangeChange(key: string, value: number, attr: 'from' | 'to') {
    const filterConfig = this.config[key];

    if (filterConfig.type === 'number-range') {
      if (!this._currentValues?.[key]?.value) {
        this.initFilterRecord(key, {});
      }
      const currentValue = this._currentValues[key].value;

      const index = this.filtersService.getCustomIndexValue(filterConfig, attr);

      const fromIndex = this.filtersService.getCustomIndexValue(filterConfig, 'from');

      const toIndex = this.filtersService.getCustomIndexValue(filterConfig, 'to');

      currentValue[index] = value;

      if (currentValue[fromIndex] == null && currentValue[toIndex] == null) {
        this._currentValues[key].value = null;
      } else {
        if (currentValue[fromIndex] == null) {
          delete this._currentValues[key].value[fromIndex];
        }

        if (currentValue[toIndex] == null) {
          delete this._currentValues[key].value[toIndex];
        }
      }

      const validRange = this.filtersService.validateNumberRange(key, currentValue);
      const nullValue = !currentValue
        || Object.keys(currentValue).every(attrb => currentValue[attrb] === undefined || currentValue[attrb] === null);
      this._currentValues[key].applyDisabled = !validRange || nullValue;
    }
  }


  /**
   * Order function to display options in the the same order as the object
   * @returns constant 0
   */
  protected objOrder = () => 0;

  protected initFilterRecord(key: string, value: any) {
    if (!this._currentValues) {
      this._currentValues = { [key]: { value } };
    } else if (!this._currentValues[key]) {
      this._currentValues[key] = { value };
    } else {
      this._currentValues[key].value = value;
    }
  }

  protected updateValueCount() {
    const activeValues = this.filtersService.getActiveValues();
    let notRequiredValue = false;

    // Search if current value contains at least 1 not required filter value
    if (this.config && activeValues) {
      notRequiredValue = Object.keys(this.config).some(key => !this.config[key].required && !!activeValues[key]);
    }

    this.clearable = !!activeValues && notRequiredValue;
  }
}
