import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class RomaneosDeContratosAFijarServiceService {
  constructor(
    private http: ApiService,
    public userDataService: UserDataService
  ) { }

  /**
   * Service for Romaneos de Contratos a Fijar
   * @param page page
   * @param count count
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  public listRomaneosDeContratosAFijar(page: number, count: number,
    orderBy: string, order: string, filters: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString())
      .set('ordenadoPor', orderBy)
      .set('orden', order);

    params = this.http.addFilterParams(filters, params);

    return this.http.get('contratos/romaneos-de-contratos-a-fijar', params);
  }

  /**
   * Download a XLS associated with this service.
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  public downloadXls(orderBy: string,
    order: string, filters: any, columns: Array<string>) {
    let params = new HttpParams()
      .set('pagina', '1')
      .set('cantidad', '1')
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('ordenadoPor', orderBy)
      .set('orden', order);
    params = this.http.addFilterParams(filters, params);

    const xlsColumns = columns;
    params = this.http.addXlsParams(xlsColumns, params);

    return this.http.download('contratos/romaneos-de-contratos-a-fijar', params);
  }

  /**
   * Gets column list for romaneos grid based on the active user's cuit
   */
  public listRomaneosDeContratosAFijarColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/romaneos-de-contratos-a-fijar', params);
  }
}
