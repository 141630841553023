import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * TODO: Componente Deprecado - eliminar luego de aplicar nuevo sidebar en toda la web
 */
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent {
  public showSpinner: boolean;

  private component: any;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.component = data.component;
  }

  /**
   * Applies removal of the element based on data parameters.
   */
  confirmDelete(): void {
    this.showSpinner = true;
    this.component[this.data.service][this.data.serviceMethod](this.data.toDelete)
      .subscribe(
        (res: any) => {
          this.showSpinner = false;
          this.dialogRef.close({ confirm: true, response: res });
        },
        (err: any) => {
          this.showSpinner = false;
          this.closeDialog();
          console.error(err);
        }
      );
  }

  /**
   * Close dialog when user click cancel button
   */
  public closeDialog() {
    this.dialogRef.close({ confirm: false });
  }
}
