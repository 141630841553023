import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(public http: ApiService) { }

  /**
   * Returns the list of Products.
   */
  getProducts() {
    return this.http.get('productos');
  }
}
