<div class="modal-wrapper">
  <section class="content-page container-fluid">
      <div>
        <iframe
        src="/assets/htmls/denuncia-etica.html"
      ></iframe>
      </div>
      <hr />
      <div class="text-center">
        <button
          type="submit"
          class="btn btn-primary"
          (click)="closeDialog()"
        >Cerrar
      </button>
      </div>
  </section>
</div>
