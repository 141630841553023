import { Component, Input } from '@angular/core';
import { formatGrid } from '../../helpers/listRole';
import { TotalsStatusEnum } from './totals-status.enum';

@Component({
  selector: 'app-totals-status',
  templateUrl: './totals-status.component.html',
  styleUrls: ['./totals-status.component.scss']
})
export class TotalsStatusComponent {
  @Input() totalsStatus: TotalsStatusEnum = TotalsStatusEnum.LOADING;

  @Input() totalValue = 0;

  @Input() totalTitle = '';

  @Input() totalFormat = '';

  @Input() totalSuffix = '';

  @Input() bottomSuffix = false;

  @Input() totalDate: string;

  @Input() totalIcon: string;

  @Input() totalImage: string;

  public isLoading() {
    return this.totalsStatus === TotalsStatusEnum.LOADING;
  }

  public isDone() {
    return this.totalsStatus === TotalsStatusEnum.DONE;
  }

  public isError() {
    return this.totalsStatus === TotalsStatusEnum.ERROR;
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format, cell) {
    return formatGrid(format, cell);
  }
}
