import {
  Directive, EventEmitter, HostBinding, HostListener, Output
} from '@angular/core';

/**
 * Drag and drop directive to handle files selection.
 */
@Directive({
  selector: '[appDnD]'
})
export class DndDirective {
  // Binds the class 'fileover' to the host whenever a dragover occurs
  @HostBinding('class.fileover') fileOver: boolean;

  @Output() fileDragOver = new EventEmitter<boolean>();

  @Output() fileDragLeave = new EventEmitter<boolean>();

  @Output() fileDropped = new EventEmitter<File[]>();

  /**
   * Emits an event the first time a drag over occurs within the host
   * @param evt dragover event
   */
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (!this.fileOver) {
      this.fileOver = true;
      this.fileDragOver.emit(true);
    }
  }

  /**
   * Emits an event the first time a drag leave occurs within the host
   * @param evt dravleave event
   */
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.fileOver) {
      this.fileOver = false;
      this.fileDragLeave.emit(true);
    }
  }

  /**
   * Emits an event with the selected files when a drop occurs inside the host
   * @param evt drop event
   */
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
    const { files } = evt.dataTransfer;

    if (files?.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
