<!-- Filters Options -->
<div class="filters-wrap">

  <!-- Icons Filters -->
  <div class="filter-header">
    <agd-filters-toggle [filters]="filters" (filterValueChange)="applyFilters($event)">
    </agd-filters-toggle>
    <div class="filters-w-icons">
      <i class="icofont-cloud-download" [hidden]="showSpinner" (click)="downloadXls()" title="Descargar Excel"
        data-toggle="tooltip" data-placement="right"></i>
      <i [hidden]="!showSpinner">
        <mat-progress-spinner mode="indeterminate" diameter="19">
        </mat-progress-spinner>
      </i>
    </div>
  </div>
  <agd-filters-toolbar-anchor></agd-filters-toolbar-anchor>

</div>

<!-- Section KPI's -->
<ul class="metrics row">
  <li *ngIf="columnExists('kgVendidos')">
    <div class="metric-wrap">
      <app-totals-status [totalsStatus]="totalsStatus" [totalValue]="totalKgVendidos"
        [totalTitle]="totalKgVendidosTitle" [totalFormat]="'wo-decimals'">
      </app-totals-status>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiSeltWidth+'%'" aria-valuenow="kpiSeltWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('kgAplicados')">
    <div class="metric-wrap">
      <app-totals-status [totalsStatus]="totalsStatus" [totalValue]="totalKgAplicados"
        [totalTitle]="totalKgAplicadosTitle" [totalFormat]="'wo-decimals'">
      </app-totals-status>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiAppliedWidth+'%'" aria-valuenow="kpiAppliedWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('kgPendientes')">
    <div class="metric-wrap">
      <app-totals-status [totalsStatus]="totalsStatus" [totalValue]="totalKgPendientes"
        [totalTitle]="totalKgPendientesTitle" [totalFormat]="'wo-decimals'">
      </app-totals-status>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiPendingWidth+'%'" aria-valuenow="kpiPendingWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

  <li *ngIf="columnExists('saldoPesos')">
    <div class="metric-wrap">
      <app-totals-status [totalsStatus]="totalsStatus" [totalValue]="totalSaldoPesos"
        [totalTitle]="totalSaldoPesosTitle" [totalFormat]="'w-decimals'">
      </app-totals-status>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="kpiBalanceWidth+'%'" aria-valuenow="kpiBalanceWidth"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </li>

</ul>

<!-- Contenedor Tabla -->
<div class="main-table">
  <div class="mat-table-container">

    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource!==undefined && (dataColumns.length !== 0)">

      <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
          <agd-table-column-header [headerData]="headerColumns[i]"
            (changeOrder)="orderTable($event.column, $event.order)">
          </agd-table-column-header>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- Link para Parte de Venta-->
          <a *ngIf="headerColumns[i].filter === 'parteDeVenta'" (click)="viewContractDetails(element.parteDeVenta)">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <!-- Link para Numero de Contrato-->
          <a *ngIf="headerColumns[i].filter === 'nroContrato'" (click)="viewContractDetails(element.nroContrato)">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <!-- Link para Kg Aplicados -->
          <a *ngIf="headerColumns[i].filter === 'kgAplicados'" href="javascript:void(0)"
            (click)="viewAplicacionesDetails(element.nroContrato || element.parteDeVenta)">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <!-- Link para Numero de Contrato a Fijar-->
          <a *ngIf="headerColumns[i].filter === 'nroContratoAFijar' && element.nroContratoAFijar !== '---'"
            href="javascript:void(0)"
            (click)="viewContratoAFijarDetails(transform(headerColumns[i].format, element.nroContratoAFijar))">
            {{transform(headerColumns[i].format,element[column])}}
          </a>
          <div (click)="viewDetails(element)" *ngIf="(headerColumns[i].filter !== 'parteDeVenta'
                && headerColumns[i].filter !== 'nroContrato'
                && headerColumns[i].filter !== 'kgAplicados'
                && headerColumns[i].filter !== 'nroContratoAFijar')
                || (headerColumns[i].filter === 'nroContratoAFijar' && element.nroContratoAFijar === '---')">
            {{transform(headerColumns[i].format,element[column])}}
          </div>
        </mat-cell>
        <div *ngIf="headerColumns[i].filter !== 'saldoPesos'">
          <mat-footer-cell *matFooterCellDef>
            <div *ngIf="headerColumns[i].total !== undefined">
              <img *ngIf="totalsStatus !== 'done'" src="/assets/images/spinner-dots.gif" alt="spinner" width="16" />
              <div *ngIf="totalsStatus === 'done'">
                {{getColumnTotal(headerColumns[i].total) | formatNumber}}
              </div>
            </div>
          </mat-footer-cell>
        </div>
        <div *ngIf="headerColumns[i].filter === 'saldoPesos'">
          <mat-footer-cell *matFooterCellDef>
            <img *ngIf="totalsStatus !== 'done'" src="/assets/images/spinner-dots.gif" alt="spinner" width="16" />
            <div *ngIf="totalsStatus === 'done'">
              {{transform(headerColumns[i].format,getColumnTotal(headerColumns[i].total))}}
            </div>
          </mat-footer-cell>
        </div>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns; sticky: true"></mat-header-row>
      <mat-row class="hovereable-row" tabindex="1" [hidden]="!(tableStatus === 'done')"
        *matRowDef="let row; columns: dataColumns;"></mat-row>
      <mat-footer-row [class.table-footer-hidden]="tableStatus !== 'done'"
        *matFooterRowDef="dataColumns"></mat-footer-row>
    </mat-table>
  </div>

  <div *ngIf="tableStatus !== 'done'" class="table-status-container">
    <app-table-status [tableStatus]="tableStatus" [dataColumns]="dataColumns"></app-table-status>
  </div>

  <div [hidden]="!(tableStatus === 'done')" *ngIf="dataColumns.length !== 0">
    <mat-paginator [length]="dataSourceTotal" [pageSize]="tableattr.count" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="handlePage($event)">
    </mat-paginator>
  </div>

</div>

<div class="grid-footer-label-container" [ngClass]="{ invisible: !(tableStatus === 'done') }">
  <div class="alert" role="alert">
    <span>Última actualización: {{ fechaUltimaActualizacionDatos | date: 'dd/MM/yy HH:mm' }}</span>
  </div>
</div>