import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-phone-modal',
  templateUrl: './add-phone-modal.component.html',
  styleUrls: ['./add-phone-modal.component.scss']
})
export class AddPhoneModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AddPhoneModalComponent>
  ) {}

  /**
   * Close dialog and shows a message.
   * @param event info about close action.
   */
  public closeDialog(event: any) {
    this.dialogRef.close({ message: event });
  }
}
