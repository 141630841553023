import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RowDetailsChangedService {
  @Output() showRowDetails = new EventEmitter<any>();

  @Output() closeRowDetails = new EventEmitter<boolean>();

  /**
   * Informs that row details want to be displayed.
   * @param data row details
   */
  rowDetailsChanged(data: any) {
    this.showRowDetails.emit(data);
  }

  closeDetailsChanged() {
    this.closeRowDetails.emit(true);
  }
}
