import { ModulesPermissions } from 'src/app/_shared/helpers/applicationConstants';
import {
  Component, OnDestroy, OnInit, ViewChild, ElementRef
} from '@angular/core';
import { ProductsService } from 'src/app/_core/services/products/products.service';
import { HarvestsService } from 'src/app/_core/services/harvests/harvests.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ContractsToSetService } from 'src/app/_core/services/contracts-to-set/contracts-to-set.service';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { Subject } from 'rxjs';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { capitalize, exportXLS } from 'src/app/_shared/helpers/fileHelper';
import {
  completeSubscription,
  fillGrid,
  formatGrid,
  getColumnTotal,
  getRowDetails,
  resetPaginator,
  userTooltips,
} from 'src/app/_shared/helpers/listRole';
import { takeUntil } from 'rxjs/operators';
import { RowDetailsChangedService } from 'src/app/_core/services/common/row-details-changed/row-details-changed.service';
import { FiltersConfig } from '../../modules/agd-components/filters/models/filters.types';
import { FiltersService } from '../../modules/agd-components/filters/services/filters.service';
import { DataListBaseClass } from 'src/app/_modules/pages/classes/data-list.abstract';

@Component({
  selector: 'app-contracts-to-set-page',
  templateUrl: './contracts-to-set-page.component.html',
  styleUrls: ['./contracts-to-set-page.component.scss'],
  })
export class ContractsToSetPageComponent extends DataListBaseClass implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatTable, { read: ElementRef }) table: ElementRef;

  dataSource: any;

  dataSourceTotal = 0;

  totalKgPactado = 0;

  totalKgEntregados = 0;

  totalKgPendientes = 0;

  totalKgFijados = 0;

  totalKgAFijar = 0;

  kpiDeliveredWidth = 0;

  kpiPendingWidth = 0;

  kpiSetWidth = 0;

  kpiToSetWidth = 0;

  kpiAgreedWidth = 0;

  fechaUltimaActualizacionDatos = null;

  headerColumns = [];

  filters: FiltersConfig;

  tableattr: any = {
    filters: {},
    from: '',
    to: '',
    order: '',
    orderBy: '',
    search: '',
    count: 10,
    page: 1,
    status: '',
  };

  /**
   * Used for mat-table rows definitions.
   */
  dataColumns: Array<string> = [];

  tableStatus: TableStatusEnum = TableStatusEnum.LOADING;

  /**
   * Show spinner during download process.
   */
  showSpinner: boolean;

  /**
   * Used in order to cancel request when component is destroyed.
   */
  ngUnsubscribe = new Subject();

  /**
   * Used for possible fields based on role of user.
   */
  private possibleFields = {
    fechaContrato: {
      type: 'date',
      filterName: 'fechaContrato',
      format: 'date-wo-time',
      hasDropDown: true,
    },
    nroContrato: {
      type: 'number',
      filterName: 'nroContrato',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
    },
    parteDeVenta: {
      type: 'number',
      filterName: 'parteDeVenta',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
    },
    producto: {
      data: [],
      key: 'select',
      filterName: 'producto',
      hasDropDown: true,
    },
    cosecha: {
      data: [],
      key: 'select',
      filterName: 'cosecha',
      hasDropDown: true,
    },
    kgPactado: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgPactadoDesde',
      filterTo: 'kgPactadoHasta',
      filterName: 'kgPactado',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalkgPactado',
    },
    kgEntregados: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgEntregadosDesde',
      filterTo: 'kgEntregadosHasta',
      filterName: 'kgEntregados',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalkgEntregados',
    },
    kgPendientes: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgPendientesDesde',
      filterTo: 'kgPendientesHasta',
      filterName: 'kgPendientes',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalkgPendientes',
    },
    kgFijados: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgFijadosDesde',
      filterTo: 'kgFijadosHasta',
      filterName: 'kgFijados',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalkgFijados',
    },
    kgAFijar: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgAFijarDesde',
      filterTo: 'kgAFijarHasta',
      filterName: 'kgAFijar',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalkgAFijar',
    },
    fechaVencimientoContrato: {
      filterName: 'fechaVencimientoContrato',
      key: 'fechaVencimiento',
      filterFrom: 'fechaVencimientoDesde',
      filterTo: 'fechaVencimientoHasta',
      type: 'expirationDate',
      hasDropDown: true,
      format: 'date-wo-time',
    },
    deposito: {
      type: 'input',
      filterName: 'deposito',
      hasDropDown: true,
      key: 'input',
    },
  };

  private XLSFileName = `${capitalize(this.getModule())} - Contratos a Fijar`;

  /**
   * Used to check if this is the first time we're running the permissionsChanged function since
   * we loaded this component.
   */
  private firstLoad = true;

  constructor(
    // For filter purpose: Used in order to select one of the available products.
    productsService: ProductsService,
    // For filter purpose: Used in order to select one of the available harvests.
    harvestService: HarvestsService,
    // Service that allows access to the list of Conctracts to set.
    private contractsToSetService: ContractsToSetService,
    // Service that allows to suscribe to cuit changes
    private cuitChangedService: CuitChangedService,
    // Service used for updating active user
    private userDataService: UserDataService,
    // used in order to emit row data.
    private rowDetailsChangedService: RowDetailsChangedService,
    private filtersService: FiltersService,
  ) {
    super(productsService, harvestService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.cuitChangedService.activeCuitChanged.subscribe((cuit) => this.cuitChanged(cuit));
    this.subs.sink = this.cuitChangedService.permissionsChanged.subscribe((permissions: any) => {
      if (permissions) {
        this.permissionsChanged();
      }
    });
    userTooltips();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.completeSubscription();
  }

  applyFilters(filtersValue: Record<string, string>) {
    // If it's not the first load of the 'Contratos a Fijar' apply filters and call updateContractsToSet method
    if (!this.firstLoad) {
      this.tableattr.filters = filtersValue;

      this.completeSubscription();
      this.updateContractsToSet();
    }
  }

  /**
   * Emit an event in order to show row details.
   * @param row row data.
   */
  viewDetails(row: any) {
    const data = {
      rowData: getRowDetails(this.headerColumns, row, this.dataColumns),
    };
    this.rowDetailsChangedService.rowDetailsChanged(data);
  }

  /**
   * Returns column's total
   * @param column column to show total
   */
  getColumnTotal(column) {
    return getColumnTotal(this, column);
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format, cell) {
    return formatGrid(format, cell);
  }

  /**
   * Handle Paginator event
   * @param e event
   */
  handlePage(e: any) {
    this.tableattr.page = e.pageIndex + 1;
    this.tableattr.count = e.pageSize;
    this.getContractsToSet();
  }

  /**
   * Method that takes column and order selected and applies sorting
   * @param column name of the selected column
   * @param order order selected (asc or des)
   */
  orderTable(column: string, order: string) {
    if (!(this.tableattr.orderBy === column && this.tableattr.order === order)) {
      this.completeSubscription();
      this.ngUnsubscribe = new Subject();
      this.tableattr.orderBy = column;
      this.tableattr.order = order;
      this.getContractsToSet();
    }
  }

  /**
   * Calls the XLS service to get the download link.
   */
  downloadXls() {
    this.showSpinner = true;
    const { fechaContrato, fechaVencimientoContrato, ...filters } = this.tableattr.filters || {};

    const [from, to] = this.filtersService.parseDateFromToQuery(fechaContrato);

    const [fechaVencimientoDesde, fechaVencimientoHasta] = this.filtersService.parseDateFromToQuery(fechaVencimientoContrato);

    if (fechaVencimientoDesde) {
      filters.fechaVencimientoDesde = fechaVencimientoDesde;
    }

    if (fechaVencimientoHasta) {
      filters.fechaVencimientoHasta = fechaVencimientoHasta;
    }

    this.contractsToSetService
      .downloadXls(
        from,
        to,
        this.tableattr.orderBy,
        this.tableattr.order,
        filters,
        this.dataColumns
      )
      .subscribe(
        (res: any) => {
          exportXLS(res, this.XLSFileName);
          this.showSpinner = false;
        },
        (err: any) => {
          console.error(err);
          this.showSpinner = false;
        }
      );
  }

  /**
   * Goes to romaneo de contratos a fijar page
   * @param filterValue value
   */
  viewRomaneoDetails(filterValue: any) {
    this.navigateWithFilter(
      [
        this.userDataService.isProductor()
          ? '/ventas/romaneos-de-contratos-a-fijar'
          : '/contratos/romaneos-de-contratos-a-fijar',
      ],
      'nroContrato',
      filterValue
    );
  }

  /**
   * Returns true if column is at dataColumns array.
   * Otherwise returns false.
   * @param name column name.
   */
  columnExists(name: string) {
    if (!this.dataSource) {
      return false;
    }
    return !!this.dataColumns.find((column) => column === name);
  }

  protected getFiltersConfig(): FiltersConfig {
    return {
      fechaContrato: {
        type: 'date-range',
        label: 'Fecha de Contrato',
      },
      ...(this.getModule() === ModulesPermissions.CONTRATOS && {
        nroContrato: {
          type: 'number',
          label: 'Nro Contrato',
          format: 'wo-decimals-or-separators',
        },
      }),
      ...(this.getModule() === ModulesPermissions.VENTAS && {
        parteDeVenta: {
          type: 'number',
          label: 'Parte de Venta',
          format: 'wo-decimals-or-separators',
        },
      }),
      producto: {
        type: 'radio',
        label: 'Producto',
        options: this.filtersOptions.producto,
      },
      cosecha: {
        type: 'radio',
        label: 'Cosecha',
        options: this.filtersOptions.cosecha,
      },
      kgPactado: {
        type: 'number-range',
        label: 'Kg Pactados',
        format: 'wo-decimals',
        fromAttr: 'kgPactadoDesde',
        toAttr: 'kgPactadoHasta',
      },
      kgEntregados: {
        type: 'number-range',
        label: 'Kg Entregados',
        format: 'wo-decimals',
        fromAttr: 'kgEntregadosDesde',
        toAttr: 'kgEntregadosHasta',
      },
      kgPendientes: {
        type: 'number-range',
        label: 'Kg Pendientes',
        format: 'wo-decimals',
        fromAttr: 'kgPendientesDesde',
        toAttr: 'kgPendientesHasta',
      },
      kgFijados: {
        type: 'number-range',
        label: 'Kg Fijados',
        format: 'wo-decimals',
        fromAttr: 'kgFijadosDesde',
        toAttr: 'kgFijadosHasta',
      },
      kgAFijar: {
        type: 'number-range',
        label: 'Kg a Fijar',
        format: 'wo-decimals',
        fromAttr: 'kgAFijarDesde',
        toAttr: 'kgAFijarHasta',
      },
      fechaVencimientoContrato: {
        type: 'date-range',
        label: 'Fecha de Vencimiento del Contrato',
      },
      deposito: {
        type: 'text',
        label: 'Deposito',
      },
    };
  }

  /**
   * Sets permissions and refresh menu
   */
  private permissionsChanged() {
    this.ngUnsubscribe = new Subject();
    // If this is the first time we called this function in this component's life, then be
    // careful not to reset the filters since it could happen they've been set on the constructor.
    if (!this.firstLoad) {
      this.tableattr = {
        filters: {},
        from: '',
        to: '',
        order: '',
        orderBy: '',
        search: '',
        count: 10,
        page: 1,
        status: '',
      };
    }

    this.initializeColumns();
    this.loadFiltersConfig();
  }

  /**
   * Sets active cuit and clears grid.
   * @param cuit cuit
   */
  private cuitChanged(cuit: string) {
    this.completeSubscription();
    this.userDataService.setActiveCUIT(cuit);
  }

  /**
   * Get list of Contracts To Set
   */
  private getContractsToSet() {
    this.tableStatus = TableStatusEnum.LOADING;
    const { fechaContrato, fechaVencimientoContrato, ...filters } = this.tableattr.filters || {};

    const [from, to] = this.filtersService.parseDateFromToQuery(fechaContrato);

    const [fechaVencimientoDesde, fechaVencimientoHasta] = this.filtersService.parseDateFromToQuery(fechaVencimientoContrato);

    if (fechaVencimientoDesde) {
      filters.fechaVencimientoDesde = fechaVencimientoDesde;
    }

    if (fechaVencimientoHasta) {
      filters.fechaVencimientoHasta = fechaVencimientoHasta;
    }

    this.contractsToSetService
      .listContractsToSet(
        this.tableattr.page,
        this.tableattr.count,
        from,
        to,
        this.tableattr.orderBy,
        this.tableattr.order,
        filters
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.contratosAFijar);
          this.dataSourceTotal = res.cantidadTotal;
          this.totalKgPactado = res.totalKgPactado;
          this.totalKgEntregados = res.totalKgEntregados;
          this.totalKgPendientes = res.totalKgPendientes;
          this.totalKgFijados = res.totalKgFijados;
          this.totalKgAFijar = res.totalKgAFijar;
          this.setKpis();
          this.firstLoad = false;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          if (this.tableattr.page > 0) {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos || this.fechaUltimaActualizacionDatos;
          } else {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos;
          }
        },
        (err: any) => {
          this.firstLoad = false;
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Unsubscribes all pending requests.
   */
  private completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }

  /**
     * Update grid based on applied filters.
     */
  private updateContractsToSet() {
    this.ngUnsubscribe = new Subject();
    resetPaginator(this);
    this.getContractsToSet();
  }

  /**
     * Sets all grid's kpis.
     */
  private setKpis() {
    if (this.dataSourceTotal > 0) {
      this.kpiDeliveredWidth = (this.totalKgEntregados * 100) / this.totalKgPactado;
      this.kpiPendingWidth = (this.totalKgPendientes * 100) / this.totalKgPactado;
      this.kpiSetWidth = (this.totalKgFijados * 100) / this.totalKgPactado;
      this.kpiToSetWidth = (this.totalKgAFijar * 100) / this.totalKgPactado;
      this.kpiAgreedWidth = 100;
    } else {
      this.kpiDeliveredWidth = 0;
      this.kpiPendingWidth = 0;
      this.kpiSetWidth = 0;
      this.kpiToSetWidth = 0;
      this.kpiAgreedWidth = 0;
    }
  }

  /**
   * If user has grid permission, we fill it.
   */
  private initializeColumns() {
    if (this.userDataService.hasGridPermission(this.getModule(), 'contratosAFijar')) {
      fillGrid(this, 'contractsToSetService', 'listContractsToSetColumns', 'getContractsToSet');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  /**
   * Returns module of the current url in order to check permissions.
   */
  private getModule(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? ModulesPermissions.VENTAS : ModulesPermissions.CONTRATOS;
  }
}
