import {
  Component, forwardRef, ElementRef, ViewChild, OnDestroy, Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRipple, RippleRef } from '@angular/material/core';
import { AppAnimations } from '../../animations/animations';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss'],
  animations: [AppAnimations.fadeIn],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SelectFileComponent),
      multi: true,
    },
  ],
})
export class SelectFileComponent implements OnDestroy, ControlValueAccessor {
  @ViewChild('fileDropRef') fileInput: ElementRef;

  @ViewChild(MatRipple) ripple: MatRipple;

  @Input() acceptTypes: string;

  selected: File;

  disabled = false;

  rippleColor = 'rgba(3, 94, 230, 0.1)';

  private rippleRef: RippleRef;

  onChange: any = () => { };

  onTouched: any = () => { };

  ngOnDestroy() {
    this.rippleOff();
  }

  handleFile(event) {
    if (this.disabled) return;

    const [file]: File[] = event.target.files || [];

    this.selected = file;

    this.rippleOff();
    this.onTouched();
    this.onChange(this.selected);
  }

  clearSelection(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.disabled) return;
    this.selected = null;
    this.fileInput.nativeElement.value = '';

    this.onChange(this.selected);
  }

  rippleOn() {
    this.rippleOff();

    this.rippleRef = this.ripple.launch({
      persistent: true,
      centered: true
    });
  }

  rippleOff() {
    this.rippleRef?.fadeOut();
  }

  // ControlValueAccessor methods implementation to use "formControlName" in this custom component
  writeValue(value: File) {
    this.selected = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
