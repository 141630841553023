<!-- ICONS -->
<div class=total-icon *ngIf="isDone()">
	<img *ngIf="totalImage" class="total-image" src="../../../assets/images/{{totalImage}}">
	<i *ngIf="totalIcon" class="{{totalIcon}}"></i>
</div>
<!-- /ICONS -->
<h2 *ngIf="totalDate">{{ totalDate }}</h2>
<h4 class="total-title">{{ totalTitle }}</h4>
<!-- SPINNER -->
<div *ngIf="!isDone()" class="kpi-spinner">
	<p class="totals-status" *ngIf="isLoading()">
		<mat-progress-spinner mode="indeterminate" diameter="31" color="primary">
		</mat-progress-spinner>
	</p>
	<p *ngIf="isError()" class="totals-status text-center">
		<i class="icofont-sad"></i>
		Error al cargar datos.
	</p>
</div>
<!-- /SPINNER -->
<!-- VALUE -->
<div *ngIf="isDone()">
	<p class="total-value-container" [ngClass]="{ 'column': bottomSuffix }">
		<span class="total-value">{{ transform(totalFormat,totalValue) }}</span>
		<small> {{ totalSuffix }} </small>
	</p>
</div>
<!-- /VALUE -->