<div id="wrapper">
  <!-- No hay Resultados -->
  <p class="table-status text-center" *ngIf="isEmpty()">
    <i class="icofont-sunny"></i>
    No hay resultados.
  </p>

  <!-- Cargando -->
  <p class="table-status loading-result text-center" *ngIf="isLoading()">
    <mat-progress-spinner mode="indeterminate" diameter="35" class="loading-spinner" color="primary">
    </mat-progress-spinner>
    Cargando...
  </p>

  <!-- Error -->
  <p class="table-status text-center" *ngIf="isError()">
    <i class="icofont-sad"></i>
    Error al cargar datos.
  </p>

  <!-- No hay columnas -->
  <p class="table-status text-center" *ngIf="permission() && !isLoading()">
    <i class="icofont-gears"></i>
    El usuario no tiene permisos para ver información en esta grilla.
  </p>
</div>