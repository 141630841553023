import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';

declare var $;

@Component({
  selector: 'app-list-filters-modal',
  templateUrl: './list-filters-modal.component.html',
  styleUrls: ['./list-filters-modal.component.scss']
})
export class ListFiltersModalComponent implements OnInit {
  public minDate: Date;

  public maxDate: Date;

  public minNumber: number;

  public maxNumber: number;

  /**
   * Variables used for error messages.
   */
  public showErrorMessage = false;

  public errorKeys: Array<{ key: string; message: string }> = [];

  public errorMessage: string;

  type = '';

  // Filters for table
  tableattr: any = {};

  // Available filters keys
  availableFilterKeys: Array<string> = [];

  // Used filters keys
  usedFilterKeys: Array<string> = [];

  // filters information
  filters: Object = {};

  filtersKeys: Array<string> = [];

  // all filter keys
  isSettingFilter = true;

  // new type of filter
  newFilterType: any;

  // Show or Hide Apply button
  disabledApplyButton = true;

  filterFrom: any = '';

  filterTo: any = '';

  constructor(
    private dialogRef: MatDialogRef<ListFiltersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.setMinAndMax();
    this.filters = data.availableFilters;
    this.filtersKeys = Object.keys(this.filters);
    this.tableattr = JSON.parse(JSON.stringify(this.data.tableattr));
    this.type = data.type;
    this.filterFrom = data.filterFrom;
    this.filterTo = data.filterTo;
    if (this.type !== 'dates' && this.type !== 'kg' && this.type !== 'numberRange' && this.type !== 'expirationDate') {
      for (let i = 0; i < this.filtersKeys.length; i++) {
        if (this.tableattr.filters[this.filters[this.filtersKeys[i]].filterName]) {
          this.usedFilterKeys.push(this.filtersKeys[i]);
        } else {
          this.availableFilterKeys.push(this.filtersKeys[i]);
        }
      }
    } else {
      this.enableApplyButton();
    }
    // When we stringified and parsed the object before, we lost the Date type for
    // this field. So we build a Date object with it again.
    // TODO: hacerlo más genérico.
    if (this.tableattr.filters && this.tableattr.filters.fechaVencimiento) {
      if (this.tableattr.filters.fechaVencimientoDesde) {
        this.tableattr.filters.fechaVencimientoDesde = new Date(this.tableattr.filters.fechaVencimientoDesde);
      }
      if (this.tableattr.filters.fechaVencimientoHasta) {
        this.tableattr.filters.fechaVencimientoHasta = new Date(this.tableattr.filters.fechaVencimientoHasta);
      }
    }
    if (this.tableattr.filters?.fechaFinalizacion) {
      if (this.tableattr.filters.fechaFinalizacionDesde) {
        this.tableattr.filters.fechaFinalizacionDesde = new Date(this.tableattr.filters.fechaFinalizacionDesde);
      }
      if (this.tableattr.filters.fechaFinalizacionHasta) {
        this.tableattr.filters.fechaFinalizacionHasta = new Date(this.tableattr.filters.fechaFinalizacionHasta);
      }
    }
  }

  /**
   * Close dialog when user click cancel button
   */
  public closeDialog() {
    this.dialogRef.close({ applied: false });
  }

  /**
   * close dialog and apply filters
   */
  public applyFilter() {
    this.dialogRef.close({ applied: true, deleted: false, tableattr: this.tableattr });
  }

  ngOnInit() {
    const component = this;
    $('div').on('keyup', (e) => {
      if (e.keyCode === 13 && !component.disabledApplyButton) {
        component.applyFilter();
      }
    });
  }

  /**
   * Sets minimun and maximum year of date picker.
   */
  public setMinAndMax() {
    this.minDate = new Date(2015, 0, 1);
    const today = new Date();
    this.maxDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    this.minNumber = -999999999999999;
    this.maxNumber = 999999999999999;
  }

  /**
   * Returns the min number based on the field's key.
   * @param key field key
   */
  public getMinNumber(key: string) {
    return (key === 'numberRange') ? this.minNumber : 0;
  }

  /**
   * Add new filter to the query
   */
  addNewFilter() {
    this.isSettingFilter = true;
    this.newFilterType = null;
    this.enableApplyButton();
  }

  /**
   * Enables apply button.
   */
  enableApplyButton() {
    this.disabledApplyButton = false;
  }

  /**
   * add key to used filters and remove from available filters
   * @param key key
   */
  changeUsedFilters(key: string) {
    this.usedFilterKeys.push(key);
    this.availableFilterKeys = this.availableFilterKeys.filter((item) => item !== key);
    this.isSettingFilter = false;
    this.newFilterType = null;
    this.enableApplyButton();
  }

  /**
   * delete key filter form the query
   * @param key key
   */
  deleteFilter(key: string) {
    this.availableFilterKeys.push(key);
    this.usedFilterKeys = this.usedFilterKeys.filter((item) => item !== key);
    delete this.tableattr.filters[this.filters[key].filterName];
    if (this.filters[key].key === 'kg' || this.filters[key].key === 'numberRange' || this.filters[key].key === 'fechaVencimiento') {
      delete this.tableattr.filters[this.filters[key].filterFrom];
      delete this.tableattr.filters[this.filters[key].filterTo];
    }
    this.enableApplyButton();
    if (this.usedFilterKeys.length === 0) {
      this.isSettingFilter = true;
      this.newFilterType = null;
    }
  }

  /**
   * delete filter 'from' and 'to' from the query
   */
  deleteDateFilter() {
    delete this.tableattr.from;
    delete this.tableattr.to;
    this.enableApplyButton();
  }

  /**
   * delete from/to filter from the query
   */
  deleteFromToFilter() {
    delete this.tableattr.filters[this.filterFrom];
    delete this.tableattr.filters[this.filterTo];
    this.enableApplyButton();
  }

  /**
   * Show add filter button
   */
  showAddFilterButton() {
    return this.availableFilterKeys.length !== 0 && !this.isSettingFilter;
  }

  /**
   * Show apply filter button
   */
  showApplyButton() {
    return this.disabledApplyButton;
  }

  /**
   * Checks if dates selected by user are realistic.
   * @param mode mode
   * @param fromKey fromKey
   * @param toKey toKey
   */
  public checkDates(mode: string, fromKey?: string, toKey?: string) {
    const from = !fromKey ? this.tableattr.from : this.tableattr.filters[fromKey];
    const to = !toKey ? this.tableattr.to : this.tableattr.filters[toKey];
    if (from && to && moment(from).startOf('day') > moment(to).endOf('day')) {
      if (!fromKey && !toKey) {
        if (mode === 'from') {
          this.tableattr.to = this.tableattr.from;
        } else {
          this.tableattr.from = this.tableattr.to;
        }
      } else if (mode === 'from') {
        this.tableattr.filters[toKey] = this.tableattr.filters[fromKey];
      } else {
        this.tableattr.filters[fromKey] = this.tableattr.filters[toKey];
      }
    }
    this.enableApplyButton();
  }

  /**
   * Checks if range selected by user are realistics.
   * @param from from value
   * @param to to value
   * @param mode from or to value changed
   * @param key filter key
   */
  public checkRange(from: any, to: any, mode: string, key?: string) {
    if ((from && to) || (from >= 0 && to === 0)) {
      if (mode === 'from') {
        if (from > to) {
          this.showError('El campo desde no debe ser mayor que el hasta.', key);
        } else {
          this.hideError(key);
        }
      } else if (to < from) {
        this.showError('El campo hasta no debe ser menor que el desde.', key);
      } else {
        this.hideError(key);
      }
    } else if (!from && !to) {
      this.disabledApplyButton = true;
    } else {
      this.hideError(key);
    }
  }

  /**
   * Shows error on filter form.
   * @param message error message.
   * @param key filter key
   */
  public showError(message: string, key?: string) {
    this.disabledApplyButton = true;
    this.errorMessage = message;
    this.showErrorMessage = true;
    if (key) {
      const found = this.errorKeys.find(item => item.key === key);
      if (!found) {
        this.errorKeys.push({ key, message });
      }
    }
  }

  /**
   * Hides error for filter form.
   * @param key filter key.
   */
  public hideError(key?: string) {
    this.enableApplyButton();
    this.showErrorMessage = false;
    if (key) {
      this.errorKeys = this.errorKeys.filter((item) => item.key !== key);
    }
  }

  /**
   * Returns true if has to show error for key filter.
   * Otherwise returns false.
   * @param key key filter
   */
  public isErrorKey(key: string): boolean {
    return !!this.errorKeys.find((item) => item.key === key);
  }

  /**
   * Return error message based on filter key
   * @param key filter key
   */
  public getErrorMessage(key: string) {
    const errorKey = this.errorKeys.find(item => item.key === key);
    return (errorKey) ? errorKey.message : null;
  }

  /**
   * Checks if input of type text is alphanumeric.
   * @param value value
   * @param type type
   * @param key key
   */
  public checkInput(value: any, type: string, key?: string) {
    if (value.trim() === '') {
      this.hideError(key);
      this.disabledApplyButton = true;
    } else if (type === 'number' && value > this.maxNumber) {
      this.showError('El número ingresado supera el máximo posible.', key);
    } else if (type === 'number' && value < this.minNumber) {
      this.showError('Debe ingresar un número mayor a cero.', key);
    } else if (type === 'text' && !(typeof value === 'string')) {
      this.showError('Debe ingresar al menos un caracter alfabético.', key);
    } else {
      this.hideError(key);
    }
  }
}
