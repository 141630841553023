import { Injectable } from '@angular/core';
import { formatDateParam } from 'src/app/_shared/helpers/dates';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class PreciosService {
  constructor(
    private http: ApiService
  ) { }

  /**
   * Returns 'Precios' List based on params and filters.
   * @param date date
   * @param filters filters
   */
  listPrecios(date: Date, filters: any) {
    let params = new HttpParams()
      .set('fecha', date ? formatDateParam(date) : undefined);
    params = this.http.addFilterParams(filters, params);

    return this.http.get('unco-colaboradores/precios', params);
  }

  /**
   * Returns 'Precios Historial' list based on filters.
   * @param filters filters
   */
  listPreciosHistorial(filters: any) {
    let params = new HttpParams();
    params = this.http.addFilterParams(filters, params);

    return this.http.get('unco-colaboradores/precios/historial', params);
  }

  /**
   * Returns list of 'Productos' availables.
   */
  listProductos() {
    return this.http.get('unco-colaboradores/productos');
  }

  /**
   * Returns the list of 'Monedas' availables.
   */
  listMonedas() {
    return this.http.get('unco-colaboradores/monedas');
  }

  /**
   * Returns the list of 'Entregas' availables.
   */
  listEntregas() {
    return this.http.get('unco-colaboradores/entrega');
  }
}
