import {
  Component, OnDestroy, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { Subject, Subscription } from 'rxjs';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { exportXLS } from 'src/app/_shared/helpers/fileHelper';
import {
  completeSubscription,
  fillGrid,
  formatGrid,
  getRowDetails,
  resetPaginator,
  userTooltips,
} from 'src/app/_shared/helpers/listRole';
import { RowDetailsChangedService } from 'src/app/_core/services/common/row-details-changed/row-details-changed.service';
import { takeUntil } from 'rxjs/operators';
import {
  RomaneosDeContratosAFijarServiceService
} from 'src/app/_core/services/romaneos-de-contratos-a-fijar/romaneos-de-contratos-a-fijar-service.service';
import { FiltersConfig } from 'src/app/_shared/modules/agd-components/filters/models/filters.types';
import { DataListBaseClass } from 'src/app/_modules/pages/classes/data-list.abstract';

@Component({
  selector: 'app-romaneos-de-contratos-a-fijar-page',
  templateUrl: './romaneos-de-contratos-a-fijar-page.component.html',
  styleUrls: ['./romaneos-de-contratos-a-fijar-page.component.scss'],
  })
export class RomaneosDeContratosAFijarPageComponent extends DataListBaseClass implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatTable, { read: ElementRef }) table: ElementRef;

  noFilters: boolean;

  dataSource: any;

  dataSourceTotal = 0;

  filters: FiltersConfig;

  fechaUltimaActualizacionDatos = null;

  headerColumns = [];

  /**
   * Show spinner during download process.
   */
  showSpinner: boolean;

  /**
   * Used for possible fields based on role of user.
   */
  possibleFields = {
    ticket: {
      key: 'input',
      type: 'number',
      filterName: 'ticket',
      format: 'wo-decimals-or-separators',
      hasDropDown: true,
    },
    cartaDePorte: {
      key: 'input',
      type: 'number',
      filterName: 'cartaDePorte',
      format: 'wo-decimals-or-separators',
      hasDropDown: true,
    },
    nroContrato: {
      key: 'input',
      type: 'number',
      filterName: 'nroContrato',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
    },
    kgAplicados: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgAplicadosDesde',
      filterTo: 'kgAplicadosHasta',
      filterName: 'kgAplicados',
      hasDropDown: true,
      format: 'wo-decimals',
    },
    kgAFijar: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgAFijarDesde',
      filterTo: 'kgAFijarHasta',
      filterName: 'kgAFijar',
      hasDropDown: true,
      format: 'wo-decimals',
    },
  };

  dataColumns: Array<string> = [];

  cuitChangesSubscription: Subscription;

  tableStatus: TableStatusEnum = TableStatusEnum.LOADING;

  /**
   * Used in order to cancel request when component is destroyed.
   */
  ngUnsubscribe = new Subject();

  tableattr: any = {
    filters: {},
    order: '',
    orderBy: '',
    search: '',
    count: 10,
    page: 1,
    status: '',
  };

  private XLSFileName =
  this.getModule() === 'ventas'
    ? 'Ventas y Pagos - Romaneos de Contratos a Fijar'
    : 'Contratos - Romaneos de Contratos a Fijar';

  constructor(
    // Service used for getting romaneos list
    private romaneosDeContratosAFijarService: RomaneosDeContratosAFijarServiceService,
    // Service that allows to suscribe to cuit changes
    private cuitChangedService: CuitChangedService,
    // Service used for updating active user
    private userDataService: UserDataService,
    // used in order to emit row data.
    private rowDetailsChangedService: RowDetailsChangedService,
  ) {
    super();
    this.initializeColumns();
  }


  /**
   * If user has grid permission, we fill it.
   */
  initializeColumns() {
    fillGrid(
      this,
      'romaneosDeContratosAFijarService',
      'listRomaneosDeContratosAFijarColumns',
      'getRomaneosDeContratosAFijar'
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.cuitChangesSubscription = this.cuitChangedService.activeCuitChanged.subscribe((cuit) => {
      this.cuitChanged(cuit);
      this.permissionsChanged();
    });
    userTooltips();
  }

  ngOnDestroy() {
    this.cuitChangesSubscription.unsubscribe();
    this.completeSubscription();
  }

  /**
   * If permissions change, we redirect user to dashboard.
   */
  permissionsChanged() {
    this.ngUnsubscribe = new Subject();
    this.router.navigate(['/dashboard']);
  }

  /**
   * Emit an event in order to show row details.
   * @param row row data.
   */
  viewDetails(row: any) {
    const data = {
      rowData: getRowDetails(this.headerColumns, row, this.dataColumns),
    };
    this.rowDetailsChangedService.rowDetailsChanged(data);
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format, cell) {
    return formatGrid(format, cell);
  }

  /**
   * Returns module of the current url in order to check permissions.
   */
  getModule(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? 'ventas' : 'contratos';
  }

  /**
   * Sets active cuit and clears grid.
   * @param cuit cuit
   */
  cuitChanged(cuit: string) {
    this.completeSubscription();
    this.userDataService.setActiveCUIT(cuit);
  }

  /**
   * Get list of Granos
   */
  getRomaneosDeContratosAFijar() {
    this.tableStatus = TableStatusEnum.LOADING;
    const { filters } = this.tableattr || {};
    this.romaneosDeContratosAFijarService
      .listRomaneosDeContratosAFijar(
        this.tableattr.page,
        this.tableattr.count,
        this.tableattr.orderBy,
        this.tableattr.order,
        filters
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.romaneos);
          this.dataSourceTotal = res.cantidadTotal;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          if (this.tableattr.page > 0) {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos || this.fechaUltimaActualizacionDatos;
          } else {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos;
          }
        },
        (err: any) => {
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Handle Paginator event
   * @param e event
   */
  handlePage(e: any) {
    this.tableattr.page = e.pageIndex + 1;
    this.tableattr.count = e.pageSize;
    this.getRomaneosDeContratosAFijar();
  }

  /**
   * Sorts grid based on selected column and direction
   * @param sort sort
   */
  sortData(sort: Sort) {
    if (!sort.direction) {
      this.tableattr.orderBy = '';
      this.tableattr.order = '';
    } else {
      this.tableattr.orderBy = sort.active;
      this.tableattr.order = sort.direction;
    }
    this.completeSubscription();
    this.ngUnsubscribe = new Subject();
    this.getRomaneosDeContratosAFijar();
  }

  /**
   * Method that takes column and order selected and applies sorting
   * @param column name of the selected column
   * @param order order selected (asc or des)
   */
  orderTable(column: string, order: string) {
    if (!(this.tableattr.orderBy === column && this.tableattr.order === order)) {
      this.completeSubscription();
      this.ngUnsubscribe = new Subject();
      this.tableattr.orderBy = column;
      this.tableattr.order = order;
      this.getRomaneosDeContratosAFijar();
    }
  }

  /**
   * Unsubscribes all pending requests.
   */
  completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }

  /**
   * Update grid based on applied filters.
   */
  updateRomaneosDeContratosAFijar() {
    this.ngUnsubscribe = new Subject();
    resetPaginator(this);
    this.getRomaneosDeContratosAFijar();
  }

  applyFilters(filtersValue: Record<string, string>) {
    this.tableattr.filters = filtersValue;

    this.completeSubscription();
    this.updateRomaneosDeContratosAFijar();
  }

  /**
   * Calls the XLS service to get the download link.
   */
  downloadXls() {
    this.showSpinner = true;
    const { ...filters } = this.tableattr.filters || {};
    this.romaneosDeContratosAFijarService
      .downloadXls(this.tableattr.orderBy, this.tableattr.order, filters, this.dataColumns)
      .subscribe(
        (res: any) => {
          exportXLS(res, this.XLSFileName);
          this.showSpinner = false;
        },
        (err: any) => {
          console.error(err);
          this.showSpinner = false;
        }
      );
  }

  protected getFiltersConfig(): FiltersConfig {
    return {
      ticket: {
        label: 'Ticket',
        type: 'number',
        format: 'wo-decimals-or-separators'
      },
      nroContrato: {
        label: 'Nro de Contrato',
        type: 'number',
        format: 'wo-decimals-or-separators',
      },
      cartaDePorte: {
        label: 'Carta de Porte',
        type: 'number',
        format: 'wo-decimals-or-separators',
      },
      kgAplicados: {
        label: 'Kg Aplicados',
        type: 'number-range',
        fromAttr: 'kgAplicadosDesde',
        toAttr: 'kgAplicadosHasta',
        format: 'w-decimals',
      },
      kgAFijar: {
        label: 'Kg A Fijar',
        type: 'number-range',
        fromAttr: 'kgAFijarDesde',
        toAttr: 'kgAFijarHasta',
        format: 'w-decimals',
      },
    };
  }
}
