import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MOBILE_APP_STORE_URLS } from '../../helpers/applicationConstants';

@Component({
  selector: 'app-browser-detector',
  templateUrl: './browser-detector.component.html',
  styleUrls: ['./browser-detector.component.scss']
  })
export class BrowserDetectorComponent implements OnInit {
  public isMobile = false;

  public isIEOrEdge = false;

  public isIOS = false;

  public redirectTo = '';

  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    // Check if browser is Internet Explorer or Edge
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    /*
    // Check if we're in a mobile device
    this.isMobile = !this.deviceService.isDesktop();

    if (this.isMobile) {
      // Check if os is iOS and set app store URL.
      this.isIOS = this.deviceService.getDeviceInfo().os.toUpperCase() === 'IOS';
      this.redirectTo = this.isIOS ? MOBILE_APP_STORE_URLS.IOS : MOBILE_APP_STORE_URLS.ANDROID;
    }
    */
  }
}
