<div class="toggle-btn"
    [class.active]="amount"
    (click)="toggle()">
    Filtrar
    <span class="ml-1" *ngIf="amount" @fadeIn>({{ amount }})</span>
    <mat-icon>filter_alt</mat-icon>
    <div class="menu-trigger-ref" [matMenuTriggerFor]="filtersAnchor"></div>
</div>

<mat-menu #filtersAnchor="matMenu" class="agd-context-menu">
    <ng-template matMenuContent let-item="item" *ngIf="displayFloatingMenu">
        <agd-filters-menu
            (click)="$event.stopPropagation();"
            (keydown.tab)="$event.stopPropagation()">
        </agd-filters-menu>
    </ng-template>
</mat-menu>
