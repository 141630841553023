import {
  Component, EventEmitter, Input, Output, SimpleChanges
} from '@angular/core';
import {
  ChartConfiguration, ChartOptions
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PreciosService } from 'src/app/_core/services/precios/precios.service';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { completeSubscription } from '../../helpers/listRole';

export interface FiltroPrecios {
  idProducto: number;
  idEntrega: number;
  idMoneda: number;
  idPosicion: number;
  fecha: string;
}

@Component({
  selector: 'app-precios-popover',
  templateUrl: './precios-popover.component.html',
  styleUrls: ['./precios-popover.component.scss']
})
export class PreciosPopoverComponent {
  @Input() precioId: number;

  @Input() filters: FiltroPrecios;

  @Input() negocio: string;

  @Output() close = new EventEmitter();

  DataStatus = TableStatusEnum;

  dataStatus: TableStatusEnum = TableStatusEnum.LOADING;

  lineChartData: ChartConfiguration<'line'>['data'];

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    spanGaps: true,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            day: 'D - M'
          },
          round: 'day',
          unit: 'day'
        },
      },
    },
  };

  popoverTitle = '';

  /**
   * Used in order to cancel request when component closed.
   */
  private ngUnsubscribe = new Subject();

  constructor(private preciosService: PreciosService) { }

  ngOnChanges(changes: SimpleChanges) {
    // we cancel ongoing requests.
    this.completeSubscription();
    this.ngUnsubscribe = new Subject();
    if (changes?.precioId
      && changes.precioId.currentValue !== 0
      && changes.precioId.currentValue !== changes.precioId.previousValue) {
      this.getPreciosHistorial();
    }
  }

  closePopover() {
    this.close.emit();
  }

  /**
   * Get list of 'Precios Historial'.
   */
  private getPreciosHistorial() {
    this.dataStatus = TableStatusEnum.LOADING;
    this.preciosService.listPreciosHistorial(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res.cantidadTotal === 0) {
            this.dataStatus = TableStatusEnum.EMPTY;
          } else {
            const negocio = this.negocio || res.negocio;

            let max: number;
            const labels = [];

            const data = res.listadoHistorial.map((itemListado) => {
              max = max ? Math.max(max, itemListado.precio) : itemListado.precio;
              labels.push(itemListado.fecha);
              return itemListado.precio;
            });

            this.popoverTitle = `${res.producto} ${negocio} ${res.moneda}`;

            this.lineChartData = {
              labels,
              datasets: [{
                data,
                fill: true,
                tension: 0.25,
                borderColor: '#1A80E2',
                backgroundColor: 'rgba(26,125,224,0.3)',
              }],
            };

            this.lineChartOptions.scales.y = {
              suggestedMin: 0,
              suggestedMax: max * 1.1,
            };

            this.dataStatus = TableStatusEnum.DONE;
          }
        },
        (err: any) => {
          console.error(err);
          this.dataStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Unsubscribes pending requests.
   */
  private completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }
}
