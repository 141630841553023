<div class="modal-header">
  <span class="modal-title center truncate">Buscador</span>
</div>

<div id="list-filters-modal" class="modal-wrapper">

  <div [ngSwitch]="true" class="row">

    <div *ngSwitchCase="type==='all'" class="col-md-12">

      <!--FILTERS LOOP -->
      <div class="inputs-box" *ngFor="let key of usedFilterKeys; index as i">

        <!-- DELETE FILTER BUTTON -->
        <div class="icon-button-wrap">
          <i class="icofont-ui-delete pointer-event"
            [class.i-kg-all]="filters[key].key === 'kg' || filters[key].key === 'numberRange' || filters[key].key === 'fechaVencimiento'"
            [class.i-kg-all-error]="(filters[key].key === 'kg' || filters[key].key === 'numberRange' || filters[key].key === 'fechaVencimiento') && isErrorKey(filters[key].filterName)"
            [class.i-input-error]="filters[key].key !== 'kg' && filters[key].key !== 'numberRange'&& filters[key].key !== 'fechaVencimiento' && isErrorKey(filters[key].filterName)"
            *ngIf="!filters[key].required" (click)="deleteFilter(key)" title="Eliminar"></i>
          <i class="icofont-ui-delete pointer-event"
            [class.i-kg-all]="filters[key].key === 'kg' || filters[key].key === 'numberRange'|| filters[key].key === 'fechaVencimiento'"
            [class.i-kg-all-error]="(filters[key].key === 'kg' || filters[key].key === 'numberRange'|| filters[key].key === 'fechaVencimiento') && isErrorKey(filters[key].filterName)"
            [class.i-input-error]="filters[key].key !== 'kg' && filters[key].key !== 'numberRange' && filters[key].key !== 'fechaVencimiento' && isErrorKey(filters[key].filterName)"
            *ngIf="filters[key].required" title="Obligatorio"></i>
        </div>

        <div class="inputs-wrap">
          <!-- COLUMN NAME TO SHOW -->
          <mat-form-field>
            <input matInput disabled placeholder="{{filters[key].placeHolder}}">
          </mat-form-field>

          <!-- COLUMN TYPE SELECT -->
          <mat-form-field *ngIf="filters[key].key === 'select'">
            <mat-select panelClass="scrollable-selector" placeholder="Seleccione una opción" [(value)]="tableattr.filters[filters[key].filterName]">
              <mat-option *ngFor="let item of filters[key].data" [value]="item"
                (onSelectionChange)="enableApplyButton()">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- COLUMN TYPE SELECT MULTIPLE-->
          <mat-form-field *ngIf="filters[key].key === 'multiple'">
            <mat-select panelClass="scrollable-selector" placeholder="Seleccione un valor" multiple
              [(value)]="tableattr.filters[filters[key].filterName]">
              <mat-select-trigger>
                {{tableattr.filters[filters[key].filterName] ? tableattr.filters[filters[key].filterName][0] : ''}}
                <span *ngIf="tableattr.filters[filters[key].filterName]?.length > 1" class="additional-selection">
                  (+{{tableattr.filters[filters[key].filterName].length - 1}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let item of filters[key].data" [value]="item"
                (onSelectionChange)="enableApplyButton()">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- COLUMN TYPE DATE -->
          <mat-form-field *ngIf="filters[key].key === 'date'">
            <input [(ngModel)]="tableattr.filters[filters[key].filterName]" matInput [matDatepicker]="i"
              placeholder="{{filters[key].placeHolder}}" (dateChange)="enableApplyButton()">
            <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
            <mat-datepicker #i></mat-datepicker>
          </mat-form-field>

          <!-- INPUTS BY DATE RANGE -->
          <div *ngIf="filters[key].key === 'fechaVencimiento'">

            <mat-form-field>
              <input [(ngModel)]="tableattr.filters[filters[key].filterFrom]" matInput [matDatepicker]="from"
                [min]="minDate" placeholder="Desde" [disabled]="true"
                (ngModelChange)="checkDates('from', filters[key].filterFrom, filters[key].filterTo)">
              <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
              <mat-datepicker #from [disabled]="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <input [(ngModel)]="tableattr.filters[filters[key].filterTo]" matInput [matDatepicker]="to"
                [max]="maxDate" placeholder="Hasta" [disabled]="true"
                (ngModelChange)="checkDates('to', filters[key].filterFrom, filters[key].filterTo)">
              <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
              <mat-datepicker #to [disabled]="false"></mat-datepicker>
            </mat-form-field>

          </div>

          <!-- COLUMN TYPE INPUT -->
          <mat-form-field *ngIf="filters[key].key === 'input'">
            <input matInput placeholder="Escriba su búsqueda" [(ngModel)]="tableattr.filters[filters[key].filterName]"
              [type]="filters[key].type"
              (input)="checkInput(tableattr.filters[filters[key].filterName],filters[key].type,filters[key].filterName)">
          </mat-form-field>
          <small class="invalid-feedback" style="display: block;"
            *ngIf="filters[key].key === 'input' && isErrorKey(filters[key].filterName)">{{
            getErrorMessage(filters[key].filterName) }}</small>

          <!-- INPUTS BY RANGE -->
          <div *ngIf="filters[key].key === 'kg' || filters[key].key === 'numberRange'">

            <mat-form-field>
              <input [(ngModel)]="tableattr.filters[filters[key].filterFrom]" matInput
                [min]="getMinNumber(filters[key].key)" [max]="maxNumber"
                onKeyPress="if(this.value.length===15) return false;" type="number" placeholder="Desde"
                (ngModelChange)="checkRange(tableattr.filters[filters[key].filterFrom], tableattr.filters[filters[key].filterTo], 'from', filters[key].filterName)">
            </mat-form-field>

            <mat-form-field>
              <input [(ngModel)]="tableattr.filters[filters[key].filterTo]" matInput
                [min]="getMinNumber(filters[key].key)" [max]="maxNumber"
                onKeyPress="if(this.value.length===15) return false;" type="number" placeholder="Hasta"
                (ngModelChange)="checkRange(tableattr.filters[filters[key].filterFrom], tableattr.filters[filters[key].filterTo], 'to', filters[key].filterName)">
            </mat-form-field>
            <small class="invalid-feedback" style="display: block;" [hidden]="!isErrorKey(filters[key].filterName)">{{
              getErrorMessage(filters[key].filterName) }}</small>

          </div>

        </div>

      </div>

      <!-- IS SETTING A FILTER : Without DELETE icon -->
      <div class="inputs-box" *ngIf="isSettingFilter">

        <div class="inputs-wrap inputs-wrap-100">

          <!-- COLUMN TITTLE FILTER -->
          <mat-form-field>
            <mat-select panelClass="scrollable-selector" placeholder="Filtrar por" [(value)]="newFilterType">
              <mat-option *ngFor="let key of availableFilterKeys" [value]="key"
                (onSelectionChange)="changeUsedFilters(key)">
                {{filters[key].placeHolder}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- COLUMN TYPE SELECT -->
          <mat-form-field *ngIf="!!newFilterType && filters[newFilterType].key === 'select'">
            <mat-select panelClass="scrollable-selector" placeholder="Seleccione una opción"
              [(ngModel)]="tableattr.filters[filters[newFilterType].filterName]">
              <mat-option *ngFor="let item of filters[newFilterType].data" [value]="item"
                (onSelectionChange)="enableApplyButton()">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- COLUMN TYPE SELECT MULTIPLE-->
          <mat-form-field *ngIf="!!newFilterType && filters[newFilterType].key === 'multiple'">
            <mat-select panelClass="scrollable-selector" placeholder="Seleccione un valor" multiple
              [(ngModel)]="tableattr.filters[filters[newFilterType].filterName]">
              <mat-select-trigger>
                {{tableattr.filters[filters[newFilterType].filterName] ?
                tableattr.filters[filters[newFilterType].filterName][0] : ''}}
                <span *ngIf="tableattr.filters[filters[newFilterType].filterName]?.length > 1"
                  class="additional-selection">
                  (+{{tableattr.filters[filters[newFilterType].filterName].length - 1}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let item of filters[newFilterType].data" [value]="item"
                (onSelectionChange)="enableApplyButton()">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- COLUMN TYPE DATE -->
          <mat-form-field *ngIf="!!newFilterType &&  filters[newFilterType].key === 'date'">
            <pre>{{newFilterType}}</pre>
            <input [(ngModel)]="tableattr.filters[filters[newFilterType].filterName]" matInput [matDatepicker]="i"
              placeholder="{{filters[newFilterType].placeHolder}}" (dateChange)="enableApplyButton()">
            <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
            <mat-datepicker #i></mat-datepicker>
          </mat-form-field>

          <!-- COLUMN TYPE INPUT -->
          <mat-form-field *ngIf="!!newFilterType && filters[newFilterType].key === 'input'">
            <input matInput placeholder="Escriba su búsqueda"
              [(ngModel)]="tableattr.filters[filters[newFilterType].filterName]" [type]="filters[newFilterType].type"
              (input)="checkInput(tableattr.filters[filters[newFilterType].filterName], filters[newFilterType].type, filters[newFilterType].filterName)">
          </mat-form-field>

          <mat-form-field *ngIf="!newFilterType">
            <input matInput disabled placeholder="Seleccione una opción">
          </mat-form-field>

        </div>

      </div>

      <button *ngIf="showAddFilterButton()" class="btn btn-link add-filter-button" (click)="addNewFilter()">
        <i class="icofont-plus"></i>
        Agregar filtro
      </button>

    </div>

    <!-- DATES FILTER : Filtrado entre fechas -->
    <div *ngSwitchCase="type==='dates'" class="col-md-12">

      <div class="inputs-box">

        <!-- DELETE FILTER BUTTON -->
        <div class="icon-button-wrap">
          <i class="icofont-ui-delete pointer-event" (click)="deleteDateFilter()" title="Eliminar"></i>
        </div>

        <!-- DATE INPUTS BY RANGE -->
        <div class="inputs-wrap">

          <mat-form-field>
            <input [(ngModel)]="tableattr.from" matInput [matDatepicker]="from" [min]="minDate" placeholder="Desde"
              [disabled]="true" (ngModelChange)="checkDates('from')">
            <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
            <mat-datepicker #from [disabled]="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input [(ngModel)]="tableattr.to" matInput [matDatepicker]="to" [max]="maxDate" placeholder="Hasta"
              [disabled]="true" (ngModelChange)="checkDates('to')">
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to [disabled]="false"></mat-datepicker>
          </mat-form-field>

        </div>

      </div>

    </div>

    <!-- DATES FILTER : Filtrado entre fechas de expiración -->
    <div *ngSwitchCase="type==='expirationDate'" class="col-md-12">

      <div class="inputs-box">

        <!-- DELETE FILTER BUTTON -->
        <div class="icon-button-wrap">
          <i class="icofont-ui-delete pointer-event" (click)="deleteFromToFilter()" title="Eliminar"></i>
        </div>

        <!-- DATE INPUTS BY RANGE -->
        <div class="inputs-wrap">

          <mat-form-field>
            <input [(ngModel)]="tableattr.filters[filterFrom]" matInput [matDatepicker]="from" [min]="minDate"
              placeholder="Desde" [disabled]="true" (ngModelChange)="checkDates('from', filterFrom, filterTo)">
            <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
            <mat-datepicker #from [disabled]="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input [(ngModel)]="tableattr.filters[filterTo]" matInput [matDatepicker]="to" [max]="maxDate"
              placeholder="Hasta" [disabled]="true" (ngModelChange)="checkDates('to', filterFrom, filterTo)">
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to [disabled]="false"></mat-datepicker>
          </mat-form-field>

        </div>

      </div>

    </div>

    <!-- NUMBER RANGE FILTER -->
    <div *ngSwitchCase="type === 'kg' || type === 'numberRange'" class="col-md-12">

      <div class="inputs-box">

        <!-- DELETE FILTER BUTTON -->
        <div class="icon-button-wrap">
          <i class="icofont-ui-delete pointer-event" [class.i-kg-single]="showErrorMessage"
            [class.i-kg-single-error]="showErrorMessage" (click)="deleteFromToFilter()" title="Eliminar"></i>
        </div>

        <!-- INPUTS BY RANGE -->
        <div class="inputs-wrap">

          <mat-form-field>
            <input [(ngModel)]="tableattr.filters[filterFrom]" matInput [min]="getMinNumber(type)" [max]="maxNumber"
              onKeyPress="if(this.value.length==15) return false;" type="number" placeholder="Desde"
              (ngModelChange)="checkRange(tableattr.filters[filterFrom], tableattr.filters[filterTo], 'from')">
          </mat-form-field>

          <mat-form-field>
            <input [(ngModel)]="tableattr.filters[filterTo]" matInput [min]="getMinNumber(type)" [max]="maxNumber"
              onKeyPress="if(this.value.length==15) return false;" type="number" placeholder="Hasta"
              (ngModelChange)="checkRange(tableattr.filters[filterFrom], tableattr.filters[filterTo], 'to')">
          </mat-form-field>
          <small class="invalid-feedback" style="display: block;" *ngIf="showErrorMessage">{{ errorMessage }}</small>

        </div>

      </div>

    </div>

    <!-- SINGLE FILTER -->
    <div *ngSwitchDefault class="col-md-12">

      <!-- COLUMN TITTLE -->
      <mat-form-field class="single-filter-field">
        <input matInput disabled placeholder="{{filters[filtersKeys[0]].placeHolder}}">
      </mat-form-field>

      <!-- COLUMN TYPE SELECT -->
      <mat-form-field class="single-filter-field" *ngIf="filters[filtersKeys[0]].key === 'select'">
        <mat-select panelClass="scrollable-selector" placeholder="Seleccione una opción"
          [(value)]="tableattr.filters[filters[filtersKeys[0]].filterName]">
          <mat-option *ngFor="let item of filters[filtersKeys[0]].data" [value]="item"
            (onSelectionChange)="enableApplyButton()">
            {{item}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- COLUMN TYPE SELECT MULTIPLE -->
      <mat-form-field class="single-filter-field" *ngIf="filters[filtersKeys[0]].key === 'multiple'">
        <mat-select panelClass="scrollable-selector" placeholder="Seleccione un valor" multiple
          [(value)]="tableattr.filters[filters[filtersKeys[0]].filterName]">
          <mat-select-trigger>
            {{tableattr.filters[filters[filtersKeys[0]].filterName] ?
            tableattr.filters[filters[filtersKeys[0]].filterName][0] : ''}}
            <span *ngIf="tableattr.filters[filters[filtersKeys[0]].filterName]?.length > 1"
              class="additional-selection">
              (+{{tableattr.filters[filters[filtersKeys[0]].filterName].length - 1}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of filters[filtersKeys[0]].data" [value]="item"
            (onSelectionChange)="enableApplyButton()">
            {{item}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- COLUMN TYPE DATE -->
      <mat-form-field class="single-filter-field" *ngIf="filters[filtersKeys[0]].key === 'date'">
        <input [(ngModel)]="tableattr.filters[filters[filtersKeys[0]].filterName]" matInput [matDatepicker]="date"
          placeholder="{{filters[filtersKeys[0]].placeHolder}}" (dateChange)="enableApplyButton()">
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>

      <!-- COLUMN TYPE INPUT -->
      <mat-form-field class="single-filter-field" *ngIf="filters[filtersKeys[0]].key === 'input'">
        <input matInput placeholder="Escriba su búsqueda"
          [(ngModel)]="tableattr.filters[filters[filtersKeys[0]].filterName]" [type]="filters[filtersKeys[0]].type"
          (input)="checkInput(tableattr.filters[filters[filtersKeys[0]].filterName],filters[filtersKeys[0]].type,filters[filtersKeys[0]].filterName)">
      </mat-form-field>
      <i class="icofont-ui-delete pointer-event" (click)="deleteFilter(filtersKeys[0])" title="Eliminar"></i>
      <small class="invalid-feedback" style="display: block;"
        [hidden]="!isErrorKey(filters[filtersKeys[0]].filterName)">{{
        getErrorMessage(filters[filtersKeys[0]].filterName) }}</small>
    </div>

  </div>

</div>

<!-- BUTTONS -->
<div class="row">
  <div class="col-md-12">
    <div class="button-row center">

      <button class="btn btn-outline-primary col-6" (click)="closeDialog()">
        Cancelar
      </button>

      <button [disabled]="showApplyButton()" class="btn btn-outline-primary col-6" (click)="applyFilter()">
        Buscar
      </button>

    </div>
  </div>
</div>
<!-- /BUTTONS -->