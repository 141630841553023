<div id="row-detail" *ngIf="hasToShowData">
  <h1>Detalle</h1>
  <div id="content-wrapper">
     <!-- Rows -->
    <div id="row-content" class="row" *ngFor="let item of rowData">
      <div id="column-left" class="col-6">
          {{item.name}}
      </div>
      <div id="column-right" class="col-6" (mouseenter)="checkCopyOption()">
          {{transform(item.format,item.value)}}
          <i class="icofont-copy" *ngIf="showCopyOption(item.column)" 
            data-toggle="tooltip" data-placement="right"
            (click)="copyValue(item.value, item.name)"
            title="Copiar">
          </i>
      </div>
    </div>
    <!-- /Rows -->
    <!-- Icons -->
    <br>
    <div id="icons" class="filters-w-icons">
      <ng-container *ngIf="showDownloadButton">
        <button type="button" class="btn btn-primary btn-sm" (click)="download()" title="Descargar PDF">
          <span class="icon material-icons-outlined" *ngIf="!showSpinner" title="Descargar PDF">
            file_download
          </span>
          {{downloadLabel}}
          <i *ngIf="showSpinner">
            <mat-progress-spinner mode="indeterminate" diameter="19">
            </mat-progress-spinner>
          </i>
        </button>
      </ng-container>

      <ng-container *ngIf="showComplaintButton">
        <button type="button" class="btn btn-orange btn-sm" (click)="makeAComplaint()" title="Reclamar" [disabled]="complaintButtonDisabled">
          <ng-container *ngIf="!showComplaintSpinner">
            <span class="icon material-icons-outlined" title="Reclamar">
              outlined_flag
            </span>
            {{complaintLabel}}
          </ng-container>
          <i *ngIf="showComplaintSpinner">
            <mat-progress-spinner mode="indeterminate" diameter="19">
            </mat-progress-spinner>
          </i>
        </button>
      </ng-container>
    </div>
    <!-- /Icons -->
  </div>
  <!-- Go Back Button -->
  <div id="footer" (click)="close()">
      <button type="button" class="btn btn-link">CERRAR</button>
  </div>
  <!-- /Go Back Button -->
</div>
