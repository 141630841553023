<div class="backdrop" (click)="closePopover()"></div>
<div class="graphic-container">
  <div class="panel">
    <div class="title-bar" *ngIf="dataStatus === DataStatus.DONE">
      <h2>{{ popoverTitle }}</h2>
      <button mat-icon-button (click)="closePopover()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="graph-container">

      <div class="empty" *ngIf="dataStatus === DataStatus.EMPTY">
        <i class="icofont-sunny"></i>
        No hay resultados.
      </div>

      <div *ngIf="dataStatus === DataStatus.LOADING" class="spinner">
        <mat-progress-spinner mode="indeterminate" diameter="19"></mat-progress-spinner>
      </div>

      <div *ngIf="dataStatus === DataStatus.DONE">
        <canvas baseChart width="650" height="240" type="line" [data]="lineChartData" [options]="lineChartOptions"
          [legend]="false">
        </canvas>
      </div>
    </div>
  </div>
</div>