<div class="modal-header">
  <span class="modal-title center truncate">Agregar Oficiales de Cuenta</span>
</div>

<div class="modal-wrapper">

  <!-- Content -->
  <section class="content-page container-fluid">

    <!-- Contenedor Form -->
    <div class="row">

      <div class="col-12">

        <div class="box">
          <form>
            <!-- Oficiales de Cuentas -->
            <div class="form-group">
                <div class="row">
                <div class="custom-control col-12">
                  <mat-form-field class="chip-list">
                    <mat-chip-grid #chipList>
                      <mat-chip-row
                        *ngFor="let oficialDeCuenta of oficialesDeCuenta"
                        [removable]="true"
                        (removed)="remove(oficialDeCuenta)">
                        {{ oficialDeCuenta }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip-row>
                      <input
                        placeholder="Seleccionar..."
                        #oficialDeCuentaInput
                        [formControl]="oficialDeCuentaCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)">
                    </mat-chip-grid>
                  </mat-form-field>
                  
                  <mat-autocomplete #auto (optionSelected)="selected($event)">
                    <mat-option *ngFor="let oficialDeCuenta of filteredOficialesDeCuenta | async" [value]="oficialDeCuenta">
                      {{oficialDeCuenta}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
              <hr>
              <div class="row justify-content-center">
                <button type="button" class="btn btn-primary"
                  (click)="apply()">Aceptar
                </button>
              </div>

            </div>
            <!-- /Oficiales de Cuentas -->
          </form>
        </div>

      </div>

    </div>

  </section>

</div>
