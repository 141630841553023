import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
  })
export class FormatNumberPipe implements PipeTransform {
  /**
   * Pipe para formatear los números con decimales y separador de miles.
   * @param value El valor pasado al pipe.
   */
  transform(value: any): any {
    // Validar que efectivamente se pase un valor.
    if (typeof value === 'undefined') {
      return '-';
    }
    if (value === null) {
      return '-';
    }
    if (value === '') {
      return '-';
    }

    const number = value.toString();
    const parts = [];

    // formatear sin decimales y con separadores de miles
    const transformedNumber = parseInt(number, 10).toLocaleString('ES-ar');
    // Si no es un número
    if (transformedNumber === 'NaN') {
      return '---';
    }
    return transformedNumber;
  }
}
