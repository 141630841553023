<div class="alert alert-warning rounded-0 alert-dismissible fade show" role="alert" *ngIf="isIEOrEdge && !isMobile">
  Para una mejor experiencia de usuario, te recomendamos que uses:
  <a href="https://www.google.com/chrome/" target="_blank" rel="noopener" class="btn btn-outline-primary btn-sm"><img src="https://www.google.com/chrome/static/images/chrome-logo.svg" width="20">&nbsp;Google Chrome</a>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="alert alert-warning rounded-0 alert-dismissible fade show" role="alert" *ngIf="isMobile">
  Para una mejor experiencia de usuario en dispositivos móviles, te recomendamos que descargues nuestra app:
  <a href="{{redirectTo}}" target="_blank" rel="noopener" class="btn btn-outline-primary btn-sm">
    &nbsp;AGD Agro
  </a>

  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
