<div *ngIf="totalItems > 0 && pages > 1" class="background">
    <div class="d-flex" [ngClass]="{'disable-button' :  currentPage == 1}" (click)="previuosPage()">
        <mat-icon class="material-icons-round">
            navigate_before
        </mat-icon>
    </div>
    <div class="numbers-page">
        <span (click)="redirectToPage(1)" *ngIf="currentPage > 2">
            1
        </span>
        <div class="separator-left" *ngIf="currentPage - 2 > 1">
            ...
        </div>
        <span (click)="redirectToPage(currentPage - 1)" *ngIf="currentPage - 1 >= 1">
            {{ currentPage - 1 }}
        </span>
        <span [class.active]="currentPage">
            {{ currentPage }}
        </span>
        <span (click)="redirectToPage(currentPage + 1)" *ngIf="currentPage < pages">
            {{ currentPage + 1 }}
        </span>
        <div class="separator-right" *ngIf="currentPage + 2 < pages">
            ...
        </div>
        <span (click)="redirectToPage(pages)" *ngIf="currentPage < pages - 1">
            {{ pages }}
        </span>
    </div>
    <div class="d-flex" [ngClass]="{'disable-button' : currentPage == pages}" (click)="nextPage()">
        <mat-icon class="material-icons-round">
            navigate_next
        </mat-icon>
    </div>
</div>