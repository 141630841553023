import { Component, Input } from '@angular/core';
import { TableStatusEnum } from './table-status.enum';

@Component({
  selector: 'app-table-status',
  templateUrl: './table-status.component.html',
  styleUrls: ['./table-status.component.scss']
  })
export class TableStatusComponent {
  @Input() tableStatus: TableStatusEnum = TableStatusEnum.LOADING;

  @Input() dataColumns: Array<string>;

  isLoading() {
    return this.tableStatus === TableStatusEnum.LOADING;
  }

  isEmpty() {
    return this.tableStatus === TableStatusEnum.EMPTY;
  }

  isDone() {
    return this.tableStatus === TableStatusEnum.DONE;
  }

  isError() {
    return this.tableStatus === TableStatusEnum.ERROR;
  }

  permission() {
    return (this.dataColumns && this.dataColumns.length === 0);
  }
}
