<div class="modal-header">
  <span class="modal-title center truncate">Añadir CUIT a Usuario</span>
</div>

<div id="list-filters-modal" class="modal-wrapper">

  <!-- Content -->
  <section class="content-page container-fluid">

    <!-- Contenedor Form -->
    <div class="row">

      <div class="col-12">

        <div id="add-cuit-form" class="box">
          <form [formGroup]="addCuitForm">

            <!-- CUIT Field -->
            <div class="form-group">
              <p class="message">Ingrese el número de CUIT: </p>

              <!-- CUIT Field -->
              <input class="form-control" placeholder="CUIT" formControlName="cuit" mask="00-00000000-0"
                [showMaskTyped]="true" [dropSpecialCharacters]="false" (focus)="cleanForm()">
              <small class="valid-feedback" style="display: block;" *ngIf="showSuccessMessage">{{ successMessage
                }}</small>
              <small class="invalid-feedback" style="display: block;" *ngIf="showErrorMessage">{{ errorMessage
                }}</small>
              <!-- /CUIT Field -->

            </div>
            <!-- /CUIT Field -->

            <hr>

            <!-- Buttons -->
            <div class="text-center">
              <button type="button" class="btn btn-primary" (click)="apply()" [disabled]="!buttonEnabled()">
                <span *ngIf="!showSpinner">
                  {{btnApplyLabel}}
                </span>
                <span *ngIf="showSpinner">
                  <mat-progress-spinner mode="indeterminate" diameter="19">
                  </mat-progress-spinner>
                </span>
              </button>
              <button type="button" class="btn btn-link" (click)="closeDialog()">Volver</button>
            </div>
            <!-- /Buttons -->

          </form>
        </div>

      </div>

    </div>

  </section>
</div>