import { CustomFieldErrorMatcher } from '../../helpers/formsCustomValidators';
import {
  AfterViewInit,
  Component, ElementRef, forwardRef, Injector, Input, ViewChild
} from '@angular/core';
import {
  ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, UntypedFormControl
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SelectImageComponent),
      multi: true
    }
  ],
})
export class SelectImageComponent implements AfterViewInit, ControlValueAccessor {
  @Input() required: boolean;

  @ViewChild('fileInput') fileInput: ElementRef;

  selected: File;

  control: UntypedFormControl;

  readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (ctrl: UntypedFormControl) => (ctrl && ctrl.invalid)
  };

  constructor(private injector: Injector) { }

  onChange: any = () => { };

  onTouched: any = () => { };

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as UntypedFormControl;
      });
    }
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  fileSelected(event) {
    const [file]: File[] = event.target.files;
    this.selected = file;

    this.onTouched();
    this.onChange(this.selected);
  }

  errorMatcher() {
    return new CustomFieldErrorMatcher(this.control);
  }

  // ControlValueAccessor methods implementation to use "formControlName" in this custom component
  writeValue(value: File) {
    this.selected = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
