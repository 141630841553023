import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-messages',
  templateUrl: './modal-messages.component.html',
  styleUrls: ['./modal-messages.component.scss']
})
export class ModalMessagesComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalMessagesComponent>,
  ) { }
}
