<div class="modal-dialog">
  <div class="modal-content">

    <button mat-icon-button class="closeModal" (click)="dialogRef.close();">
      <mat-icon title="Cerrar">close</mat-icon>
    </button>

    <div class="modal-header">
      <h1 class="p-0">Plazos para reclamos de retenciones disponibles</h1>
    </div>

    <div class="modal-body">
      <p>A partir del 10/01/2022, solo se admitirán reclamos de retenciones a través de esta plataforma.</p>
      <p>El plazo perentorio para reclamarlas es el siguiente:</p>
      <p><b>Retenciones practicadas entre el día 1 y el 15, ambos inclusive:</b> hasta el tercer día hábil inclusive de finalizada la quincena antes mencionada.</p>
      <p><b>Retenciones efectuadas desde el 16 y hasta fin de mes, ambos inclusive:</b> hasta el tercer día hábil inclusive de finalizado el mes anterior.</p>
    </div>

    <div class="modal-footer">
      <button
        mat-button
        color="cerulean-blue"
        class="w-100"
        (click)="dialogRef.close('show-tutorial');"
      >
        Ver instructivo
      </button>
    </div>

  </div>
</div>
