import { ModulesPermissions } from 'src/app/_shared/helpers/applicationConstants';
import {
  Component, OnDestroy, OnInit, ViewChild, ElementRef
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ApplicationsService } from 'src/app/_core/services/applications/applications.service';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { Subject } from 'rxjs';
import { capitalize, exportXLS } from 'src/app/_shared/helpers/fileHelper';
import {
  completeSubscription,
  fillGrid,
  formatGrid,
  getColumnTotal,
  getRowDetails,
  resetPaginator,
  userTooltips,
} from 'src/app/_shared/helpers/listRole';
import { RowDetailsChangedService } from 'src/app/_core/services/common/row-details-changed/row-details-changed.service';
import { ProductsService } from 'src/app/_core/services/products/products.service';
import { HarvestsService } from 'src/app/_core/services/harvests/harvests.service';
import { takeUntil } from 'rxjs/operators';
import { TableStatusEnum } from '../table-status/table-status.enum';
import { TotalsStatusEnum } from '../totals-status/totals-status.enum';
import { DataListBaseClass } from 'src/app/_modules/pages/classes/data-list.abstract';
import { FiltersConfig } from '../../modules/agd-components/filters/models/filters.types';
import { FiltersService } from '../../modules/agd-components/filters/services/filters.service';

@Component({
  selector: 'app-applications-page',
  templateUrl: './applications-page.component.html',
  styleUrls: ['./applications-page.component.scss'],
  })
export class ApplicationsPageComponent extends DataListBaseClass implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatTable, { read: ElementRef }) table: ElementRef;

  dataSource: any;

  dataSourceTotal = 0;

  totalKgAplicados = 0;

  kpiAplicadosWidth = 0;

  fechaUltimaActualizacionDatos = null;

  totalKgAplicadosTitle = 'KG APLICADOS';

  headerColumns = [];

  /**
   * Show spinner during download process.
   */
  showSpinner: boolean;

  dataColumns: Array<string> = [];

  tableStatus: TableStatusEnum = TableStatusEnum.LOADING;

  totalsStatus: TotalsStatusEnum = TotalsStatusEnum.LOADING;

  tableattr: any = {
    filters: {},
    from: '',
    to: '',
    order: '',
    orderBy: '',
    search: '',
    count: 10,
    page: 1,
    status: '',
  };

  /**
   * Used for possible fields based on role of user.
   */
  private possibleFields = {
    fecha: {
      type: 'date',
      filterName: 'fecha',
      format: 'date',
      hasDropDown: true,
    },
    parteDeVenta: {
      type: 'number',
      filterName: 'parteDeVenta',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
      key: ''
    },
    fechaAplicacion: {
      filterName: 'fechaAplicacion',
      type: 'date',
      hasDropDown: true,
      format: 'date',
    },
    nroContrato: {
      type: 'number',
      filterName: 'nroContrato',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
      key: ''
    },
    ticket: {
      type: 'number',
      filterName: 'ticket',
      hasDropDown: true,
      format: 'wo-decimals-or-separators',
      key: ''
    },
    nroCP: {
      type: 'number',
      filterName: 'nroCP',
      hasDropDown: true,
      key: 'input',
      format: 'wo-decimals-or-separators',
    },
    producto: {
      key: 'select',
      data: [],
      filterName: 'producto',
    },
    cosecha: {
      key: 'select',
      data: [],
      filterName: 'cosecha',
      hasDropDown: true,
    },
    kgAplicados: {
      type: 'kg',
      key: 'kg',
      filterFrom: 'kgAplicadosDesde',
      filterTo: 'kgAplicadosHasta',
      filterName: 'kgAplicados',
      hasDropDown: true,
      format: 'wo-decimals',
      total: 'totalKgAplicados',
    },
    nroCegLsg: {
      filterName: 'nroCegLsg',
      type: 'number',
      key: 'input',
      hasDropDown: true,
    },
    vendedor: {
      type: 'input',
      filterName: 'vendedor',
      hasDropDown: true,
      key: ''
    },
    corredor: {
      type: 'input',
      filterName: 'corredor',
      hasDropDown: true,
      key: ''
    },
  };

  /**
   * Used to check if this is the first time we're running the permissionsChanged function since
   * we loaded this component.
   */
  private firstLoad = true;

  private XLSFileName = `${capitalize(this.getModule())} - Aplicaciones`;

  constructor(
    // Service that return all 'Productos'
    productsService: ProductsService,
    // Service that return all 'Cosechas'
    harvestService: HarvestsService,
    // Service that return all 'Aplicaciones'
    private applicationsService: ApplicationsService,
    // Service that allows to suscribe to cuit changes
    private cuitChangedService: CuitChangedService,
    // Service used for updating active user
    private userDataService: UserDataService,
    // used in order to emit row data.
    private rowDetailsChangedService: RowDetailsChangedService,
    private filtersService: FiltersService
  ) {
    super(productsService, harvestService);
    this.subs.sink = this.cuitChangedService.activeCuitChanged.subscribe((cuit) => this.cuitChanged(cuit));
    this.subs.sink = this.cuitChangedService.permissionsChanged.subscribe((permissions: any) => {
      if (permissions) {
        this.permissionsChanged();
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    userTooltips();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.completeSubscription();
  }

  /**
   * Goes to contract details page
   * @param nroContrato contract number
   * @param nroCegLsg nro ceg/lsg
   */
  viewContractDetails(nroContrato: string, nroCegLsg: string) {
    const contractNumb = `${this.transform('wo-decimals-or-separators', nroContrato)}`;

    this.router.navigate([`${this.getUrlModule()}`, 'contratos', contractNumb, 'detalle'], {
      state: { redirectTo: `${this.getUrlModule()}/aplicaciones`, nroCegLsg }
    });
  }

  /**
   * Returns column's total
   * @param column column to show total
   */
  getColumnTotal(column: any) {
    return getColumnTotal(this, column);
  }

  /**
   * Emit an event in order to show row details.
   * @param row row data.
   */
  viewDetails(row: any) {
    const data = {
      rowData: getRowDetails(this.headerColumns, row, this.dataColumns),
    };
    this.rowDetailsChangedService.rowDetailsChanged(data);
  }

  /**
   * Goes to romaneo details page
   * @param filterValue value
   * @param filterName name
   */
  viewRomaneoDetails(filterValue: any, filterName: string) {
    this.navigateWithFilter(['/granos/analisis-de-calidad'], filterName, filterValue);
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format: string, cell: any) {
    return formatGrid(format, cell);
  }

  applyFilters(filtersValue: Record<string, string>) {
    // If it's not the first load of the 'applications' apply filters and call updateApplications method
    if (!this.firstLoad) {
      this.tableattr.filters = filtersValue;

      this.completeSubscription();
      this.updateApplications();
    }
  }

  /**
   * Handle Paginator event
   * @param e event
   */
  handlePage(e: any) {
    this.tableattr.page = e.pageIndex + 1;
    this.tableattr.count = e.pageSize;
    this.getApplications();
  }

  /**
   * Method that takes column and order selected and applies sorting
   * @param column name of the selected column
   * @param order order selected (asc or des)
   */
  orderTable(column: string, order: string) {
    if (!(this.tableattr.orderBy === column && this.tableattr.order === order)) {
      this.tableattr.orderBy = column;
      this.tableattr.order = order;
      if (this.totalsStatus === TotalsStatusEnum.LOADING) {
        this.completeSubscription();
        this.updateApplications();
      } else {
        this.completeSubscription();
        this.ngUnsubscribe = new Subject();
        this.getApplications();
      }
    }
  }


  /**
   * Calls the XLS service to get the download link.
   */
  downloadXls() {
    this.showSpinner = true;
    const { fecha, kgAplicados, ...filters } = this.tableattr.filters;

    const [from, to] = this.filtersService.parseDateFromToQuery(fecha);

    this.applicationsService
      .downloadXls(
        from,
        to,
        this.tableattr.orderBy,
        this.tableattr.order,
        filters,
        this.dataColumns
      )
      .subscribe(
        (res: any) => {
          exportXLS(res, this.XLSFileName);
          this.showSpinner = false;
        },
        (err: any) => {
          console.error(err);
          this.showSpinner = false;
        }
      );
  }

  columnExists(name: string) {
    if (!this.dataSource) {
      return false;
    }

    return !!this.dataColumns.find((column) => column === name);
  }

  protected getFiltersConfig(): FiltersConfig {
    return {
      ...(this.getModule() === ModulesPermissions.VENTAS && {
        fecha: {
          type: 'date-range',
          label: 'Fecha',
        },
        parteDeVenta: {
          type: 'number',
          label: 'Parte de Venta'
        }
      }),
      ...(this.getModule() === ModulesPermissions.CONTRATOS && {
        fecha: {
          type: 'date-range',
          label: 'Fecha de Aplicación',
        },
        nroContrato: {
          type: 'number',
          label: 'Nro Contrato',
        },
        ticket: {
          type: 'number',
          label: 'Ticket',
          format: 'wo-decimals-or-separators',
        },
      }),
      nroCP: {
        type: 'number',
        label: 'Nro CP/CTG',
        format: 'wo-decimals-or-separators',
      },
      producto: {
        type: 'radio',
        label: 'Producto',
        options: this.filtersOptions.producto,
      },
      cosecha: {
        type: 'radio',
        label: 'Cosecha',
        options: this.filtersOptions.cosecha,
      },
      kgAplicados: {
        type: 'number-range',
        label: 'Kg Aplicados',
        fromAttr: 'kgAplicadosDesde',
        toAttr: 'kgAplicadosHasta',
        format: 'wo-decimals',
      },
      nroCegLsg: {
        type: 'number',
        label: 'Nro CEG/LSG',
      },
      ...(this.getModule() === ModulesPermissions.CONTRATOS && {
        vendedor: {
          type: 'text',
          label: 'Vendedor',
        },
        corredor: {
          type: 'text',
          label: 'Corredor',
        },
      })
    };
  }

  /**
   * Sets active cuit and clears grid.
   * @param cuit cuit
   */
  private cuitChanged(cuit: string) {
    this.completeSubscription();
    this.userDataService.setActiveCUIT(cuit);
  }

  /**
  * Get list of Applications
  */
  private getApplications() {
    this.tableStatus = TableStatusEnum.LOADING;
    const { fecha, kgAplicados, ...filters } = this.tableattr.filters || {};

    const [from, to] = this.filtersService.parseDateFromToQuery(fecha);

    this.applicationsService
      .listApplications(
        this.tableattr.page,
        this.tableattr.count,
        from,
        to,
        this.tableattr.orderBy,
        this.tableattr.order,
        filters
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.aplicaciones);
          this.dataSourceTotal = res.cantidadTotal;
          this.firstLoad = false;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          if (this.tableattr.page > 0) {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos || this.fechaUltimaActualizacionDatos;
          } else {
            this.fechaUltimaActualizacionDatos = res.fechaUltimaActualizacionDatos;
          }
        },
        (err: any) => {
          console.error(err);
          this.firstLoad = false;
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Get 'Aplicaciones' totals.
   */
  private getApplicationsTotals() {
    this.totalsStatus = TotalsStatusEnum.LOADING;
    const { fecha, kgAplicados, ...filters } = this.tableattr.filters || {};

    const [from, to] = this.filtersService.parseDateFromToQuery(fecha);

    this.applicationsService
      .listApplicationsTotals(from, to, filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.totalKgAplicados = res.totalKgAplicados;
          this.totalsStatus = TotalsStatusEnum.DONE;
          this.firstLoad = false;
          this.setKpis();
        },
        (err: any) => {
          console.error(err);
          this.totalsStatus = TotalsStatusEnum.ERROR;
          this.firstLoad = false;
        }
      );
  }

  /**
   * Unsubscribes all pending requests.
   */
  private completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }

  /**
   * Update grid based on applied filters.
   */
  private updateApplications() {
    this.ngUnsubscribe = new Subject();
    this.resetKpis();
    resetPaginator(this);
    this.getApplications();
    this.getApplicationsTotals();
  }

  /**
   * If user has grid permission, we fill it.
   */
  private initializeColumns() {
    if (this.userDataService.hasGridPermission(this.getModule(), 'aplicaciones')) {
      fillGrid(this, 'applicationsService', 'listAplicationsColumns', 'getApplications', 'getApplicationsTotals');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  /**
   * Sets permissions and refresh menu
   */
  private permissionsChanged() {
    this.ngUnsubscribe = new Subject();
    // If this is the first time we called this function in this component's life, then be
    // careful not to reset the filters since it could happen they've been set on the constructor.
    if (!this.firstLoad) {
      this.tableattr = {
        filters: {},
        from: '',
        to: '',
        order: '',
        orderBy: '',
        search: '',
        count: 10,
        page: 1,
        status: '',
      };
    }

    this.initializeColumns();
    this.loadFiltersConfig();
  }

  /**
   * Sets all grid's kpis.
  */
  private setKpis() {
    if (this.totalKgAplicados > 0) {
      this.kpiAplicadosWidth = 100;
    } else {
      this.kpiAplicadosWidth = 0;
    }
  }

  /**
   * Reset KPI's values.
   */
  private resetKpis() {
    this.totalKgAplicados = 0;
    this.kpiAplicadosWidth = 0;
  }

  /**
   * Returns module of the current url in order to check permissions.
  */
  private getUrlModule(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? 'ventas' : 'contratos';
  }

  /**
   * Returns module of the current url in order to check permissions.
  */
  private getModule(): string {
    const currentUrl = this.router.url;
    return currentUrl.includes('ventas') ? ModulesPermissions.VENTAS : ModulesPermissions.CONTRATOS;
  }
}
