import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class HarvestsService {
  constructor(public http: ApiService) { }

  /**
   * Returns the list of Harvest.
   */
  getHarvests() {
    return this.http.get('cosechas');
  }
}
