<div class="btn-group dropdown-input" dropdown #dropdown="bs-dropdown">
  <button id="button-animated" dropdownToggle type="button" class="btn dropdown-toggle btn-block"
    aria-controls="dropdown-animated" [ngClass]="{ disabled: disabled }">
    <div>{{ value?.label || placeholder }}</div>
    <span class="caret"></span>
  </button>
  <ul class="dropdown-menu" id="dropdown-animated" *dropdownMenu role="menu" (click)="$event.stopPropagation()">
    <div class="dropdown-header">
      <form class="search-bar">
        <input type="text" class="form-control" [(ngModel)]="filter" [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="applyFilter($event)" placeholder="Buscar" />
        <span class="material-icons-outlined" *ngIf="!filter">search</span>
        <span class="material-icons-outlined pointer-event" *ngIf="filter" (click)="clearFilter()">clear</span>
      </form>
    </div>
    <div class="dropdown-options">
      <li role="menuitem" *ngFor="let op of filteredOptions">
        <a class="dropdown-item" [ngClass]="{ selected: value === op }" (click)="selectOption(op)">
          <span class="material-icons-round text-primary">
            {{ op.icon }}
          </span>
          <div class="item-data">
            <div class="label">{{ op.name }}</div>
            <div class="description">{{ op.description }}</div>
          </div>
        </a>
      </li>
      <li class="empty-list" role="menuitem" *ngIf="!filteredOptions?.length">
        <a class="dropdown-item"> Sin resultados </a>
      </li>
    </div>
  </ul>
</div>